import { string } from "prop-types";

export const emailValidate = (textError:string) => (email:string) => {
    return /.{2,}@.{2,}\..{2,}/.test(email) || !email ? '' : (textError ? textError : 'The email invalid');
}

export const passwordValidate = (textError:string) => (pass:string) => {
    return /^[0-9a-zA-Z]\w{4,14}$/u.test(pass) ? '' : (textError ? textError : 'The password must be more than 4 characters');
}

export const matchValidate = (comparable:string, textError:string) => (value:string) => {
    return value === comparable ? '' : (textError ? textError : "Values don't match");
}

export const minMaxValue = (min:number, max:number, textErrorMin:string, textErrorMax:string) => (value:number) => {
    value = Number(value)
    if (value > max && +max > 0) {
        return textErrorMax ? textErrorMax : `The value must not exceed ${max}`;
    } else if (value < min) {
        return textErrorMin ? textErrorMin : `The value must not be less than ${min}`;
    }
    return '';
}

export const validateLength = (min:number, max:number, textError:string) => (value:string|number) => {
    if (typeof value !== 'string' && typeof value !== 'number') {
        throw Error('Type Error in validateLength')
    } else if (value) {
        if (value.toString().length < min)
            return textError ? textError : `The value must be ${min} characters long`;
        if (max && value.toString().length > max)
            return textError ? textError : `The value must be ${max} characters long`;
    }
    return '';
}

export const boolValidate = (textError:string = '') => (state: boolean) => {
    return state === false || state === true ? '' : (textError ? textError : 'The value must be only boolean');
}

export const numberValidate = (textError:string = '') => (number:string) => {
    return !(/\D+/.test(number)) || !number ? '' : (textError ? textError : 'The value must be only digits');
}

export const isPhone = (value:string) => {
    return (/^\+?[0-9- ()]+$/.test(value));
}

export const phoneValidate = (textError:string = '') => (number:string) => {
    return (/^\+?[0-9][0-9-]{4,}?[0-9]$/.test(number)) || !number ? '' : (textError ? textError : 'The value must be only digits');
}

export const phoneSpaceValidate = (textError:string = '') => (number:string) => {
    return (/^\+?[0-9][0-9- ]{4,}?[0-9]$/.test(number)) || !number ? '' : (textError ? textError : 'The value must be only digits');
}

export const letterNumberValidate = (textError:string = '') => (value:string) => {
    return (/^([0-9A-Z])+$/ig.test(value)) || !value ? '' : (textError ? textError : 'The value must be only letter');
}

export const letterNumberSpaceValidate = (textError:string = '') => (value:string) => {
    return /^[0-9A-ZА-Яіїєьґ][0-9A-ZА-Яіїєьґ ]+[0-9A-ZА-Яіїєьґ]+$/ig.test(value) || !value ? '' : (textError ? textError : 'The value must be only letter');
}

export const letterNumberSpaceHyphenSlashValidate = (textError:string = '') => (value:string) => {
    return /^[0-9A-ZА-Яіїєьґ][0-9A-ZА-Яіїєьґ \-\\\/]+[0-9A-ZА-Яіїєьґ]+$/ig.test(value) || !value ? '' : (textError ? textError : 'The value must be only letter');
}

export const streetValidate = (textError:string = '') => (value:string) => {
    return /^[0-9A-ZА-Яіїєьґ][0-9A-ZА-Яіїєьґ \-\\\/.;,:!?\-'"\(\)\*]+[0-9A-ZА-Яіїєьґ\-\\\/.;,:!?\-'"\(\)\*]+$/ig.test(value) || !value ? '' : (textError ? textError : 'The value must be only letter');
}

export const streetValidate6Languages = (textError:string = '') => (value:string) => {
    return /^[0-9A-ZА-ЯіїєьґßüÜöÖäÄàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇñáéíóúüά-ωΑ-ώ\u0100-\u017F][0-9A-ZА-ЯіїєьґßüÜöÖäÄàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇñáéíóúüά-ωΑ-ώ\u0100-\u017F \-\\\/.;,:!?\-'"\(\)\*]+[0-9A-ZА-ЯіїєьґßüÜöÖäÄàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇñáéíóúüά-ωΑ-ώ\u0100-\u017F\-\\\/.;,:!?\-'"\(\)\*]+$/ig.test(value) || !value ? '' : (textError ? textError : 'The value must be only letter');
}

/*
\u4E00-\u9FFF - китайські ієрогліфи
*/
export const letterSpaceHyphenValidate = (textError:string = '') => (value:string) => {
    return (/^([A-Z])*[A-Z -]+?[A-Z]+?$/ig.test(value)) || !value ? '' : (textError ? textError : 'The value must be only letter');
}
export const letterNumberSpaceHyphenValidate = (textError:string = '') => (value:string) => {
    return (/^([0-9A-Za-z\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF])*[0-9A-Za-z\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF -]+?[0-9A-Za-z\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF]+?$/ig.test(value)) || !value ? '' : (textError ? textError : 'The value must be only letter');
}
export const letterSpaceHyphenValidate2 = (textError:string = '') => (value:string) => {
    return (/^([A-Za-z\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF])*[A-Za-z\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF -]+?[A-Za-z\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF]+?$/ig.test(value)) || !value ? '' : (textError ? textError : 'The value must be only letter');
}
export const letterSpaceHyphenValidate3 = (textError:string = '') => (value:string) => {
    return (/^([A-Za-z\-\/:;.,\(\)\"\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF])*[A-Za-z\-\/:;.,\(\)\"\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF -]+?[A-Za-z\-\/:;.,\(\)\"\u3000-\u303F\u3400-\u4DBF\u4E00-\u9FFF]+?$/ig.test(value)) || !value ? '' : (textError ? textError : 'The value must be only letter');
}

/* test-test */
export const letterNumberHyphenValidate = (textError:string = '') => (value:string) => {
    return (/^([A-Z0-9])*[A-Z-0-9]+?[A-Z0-9]+?$/ig.test(value)) || !value ? '' : (textError ? textError : 'The value must be only letter');
}
export const letterValidate = (textError:string = '') => (value:string) => {
    return (/^([A-Z])\+$/ig.test(value)) || !value ? '' : (textError ? textError : 'The value must be only letter');
}

export const letterValidateSpace = (textError:string = '') => (value:string) => {
    return (/^[A-ZА-Яіїєьґ][A-ZА-Яіїєьґ ]+[A-ZА-Яіїєьґ]+$/ig.test(value)) || !value ? '' : (textError ? textError : 'The value must be only letter');
}

// мови та алфавіти
// німецька - ßüÜöÖäÄ
// французька - àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ
// іспанська - ñáéíóúü
// грецька - ά-ωΑ-ώ
export const letterValidateSpace6Languages = (textError:string = '') => (value:string) => {
    return (/^[A-ZА-ЯіїєьґßüÜöÖäÄàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇñáéíóúüά-ωΑ-ώ\u0100-\u017F][A-ZА-ЯіїєьґßüÜöÖäÄàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇñáéíóúüά-ωΑ-ώ\u0100-\u017F ]+[A-ZА-ЯіїєьґßüÜöÖäÄàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇñáéíóúüά-ωΑ-ώ\u0100-\u017F]+$/ig.test(value)) || !value ? '' : (textError ? textError : 'The value must be only letter');
}

export const letterValidateSpaceSpecialSigns6Languages = (textError:string = '') => (value:string) => {
    return (/^[A-ZА-ЯіїєьґßüÜöÖäÄàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇñáéíóúüά-ωΑ-ώ\u0100-\u017F\-\/:;.,\(\)\"][A-ZА-ЯіїєьґßüÜöÖäÄàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇñáéíóúüά-ωΑ-ώ\u0100-\u017F \-\/:;.,\(\)\"]+[A-ZА-ЯіїєьґßüÜöÖäÄàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇñáéíóúüά-ωΑ-ώ\u0100-\u017F\-\/:;.,\(\)\"]+$/ig.test(value)) || !value ? '' : (textError ? textError : 'The value must be only letter');
}

export const letterValidateSpaceSpecialSigns = (textError:string = '') => (value:string) => {
    return (/^[A-ZА-Яіїєьґ\-\/:;.,\(\)\"][A-ZА-Яіїєьґ \-\/:;.,\(\)\"]+[A-ZА-Яіїєьґ\-\/:;.,\(\)\"]+$/ig.test(value)) || !value ? '' : (textError ? textError : 'The value must be only letter');
}

export const letterValidate2 = (textError:string = '') => (value:string) => {
    return (/^([A-ZА-Яіїєьґ])+$/ig.test(value)) || !value ? '' : (textError ? textError : 'The value must be only letter');
}

export const numberFloatValidate = (textError:string = '') => (number:string) => {
    return checkOnNum(number) ? '' : (textError ? textError : 'The value must be only digits');
}

export const checkOnNum = (number:string) => {
    return (/^\d*[.,]?\d*$/g).test(number) || !number;
}

export const noEmptyValidate = (textError:string = '') => (value:string) => {
    return (/.+/.test(value)) ? '' : (textError ? textError : 'The field cannot be empty');
}

export const noEmptyArrayValidate = (textError:string = '') => (array: any[]) => {
    return array.length ? '' : (textError ? textError : 'The field cannot be empty');
}

export const customValidate = (regex:RegExp, textError:string = '') => (value:string) => {
    return (regex.test(value)) ? '' : (textError ? textError : 'Invalid field value');
}

export const excludedValidate = (excludedWords:Array, start:number, end:number, textError:string) => (value:string) => {
    const v = value.toString().slice(start, end ? end : value.toString().length).toLowerCase()
    for (let i = 0; i < excludedWords.length; i++) {
        const w = excludedWords[i].toString().toLowerCase();
        const regexp = new RegExp(`(${w})`)
        if (regexp.test(v)) {
            return textError ? textError : 'Invalid input'
        }
    }
    return '';
}

export const withLastLetter = (valuelength: number, textError:string = '') => (value:string) => {
    const reg = `\\d{${valuelength}}\\w$`;
    var regex = new RegExp(reg, 'ig')
    return (regex.test(value)) || !value ? '' : (textError ? textError : 'The value must be only digits and letter ');
}

import React, { memo }     from 'react';
import PropTypes           from "prop-types";
import { useTranslation }  from "react-i18next";

import InputText           from "./InputText";
import { NAME }            from "../../constants";

const InputName = ({id, type, placeholder, classes, errorMessage, required, value, closeButton, handleChange, handleBlur, validations}) => {

    const {t} = useTranslation();

    return (
        <InputText
            id={id ?? NAME}
            type={type ?? 'text'}
            placeholder={placeholder ?? t('name')}
            classes={`form-placeholder form-input ${classes ?? ''}`}
            errorMessage={errorMessage}
            required={required}
            value={value}
            handleChange={handleChange}
            closeButton={closeButton}
            validations={validations}
            handleBlur={handleBlur}
        />
    );
};

InputName.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    classes: PropTypes.string,
    errorMessage: PropTypes.string,
    required: PropTypes.bool
}

export default memo(InputName);

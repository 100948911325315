import React         from 'react';

import HelpVideo     from "./HelpVideo";
import ExcelTemplate from "./ExcelTemplate";
import BalanceButton from "./BalanceButton";
import NotifBtn      from "./NotifBtn";
import LangBlock     from "../shared/LangBlock";
import ProfileButton from "./ProfileButton";
import LogoutButton  from "./LogoutButton";

const HeaderLarge = ({className}) => {
    return (
        <div className={`row no-gutters ${className}`}>
            <div className={'d-flex align-items-center justify-content-start col-auto'}>
                <HelpVideo className={'mr-5'}/>
                <ExcelTemplate/>
            </div>
            <div className={"d-flex align-items-center justify-content-end col"}>
                <BalanceButton/>
                <NotifBtn classNameContainer={'ml-2'}/>
                <LangBlock className={'ml-2'}/>
                <ProfileButton className={'ml-5 cursor-pointer'}/>
                <LogoutButton className={'ml-3'}/>
            </div>
        </div>
    );
};

export default HeaderLarge;

import React           from 'react';
import { useDispatch } from "react-redux";

import HelpVideo       from "./HelpVideo";
import Logo            from "../shared/Logo";
import BurgerMenu      from "../shared/BurgerMenu";
import { setShowMenu } from "../../storage/global";

const HeaderSmall = ({className}) => {

    const dispatch = useDispatch();

    function showMenu(e) {
        dispatch(setShowMenu(true))
    }

    return (
        <div className={`d-flex ${className}`}>
            <div className={'col d-flex justify-content-start'}>
                <BurgerMenu className={'cursor-pointer'} handle={showMenu}/>
            </div>
            <Logo className={'col d-flex justify-content-center align-items-center'} styleLogo={{
                width: '72px'
            }}/>
            <div className={'col d-flex justify-content-end'}>
                <HelpVideo className={''}/>
            </div>
        </div>
    );
};

export default HeaderSmall;

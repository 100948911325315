import React            from 'react';
import { useSelector }  from "react-redux";

export default function Loader(props) {

    const load = useSelector(state => state.global.load)

    return (
        load ?
            <div id='main-loader'>
                <div className="loader">
                    <div id='loader-arrow-1'/>
                    <div id='loader-arrow-2'/>
                    <div id='loader-arrow-3'/>
                    <div id='loader-arrow-4'/>
                </div>
            </div>
            : ''
    );
}

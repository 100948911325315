import React       from 'react';
import { Spinner } from "react-bootstrap";

const LoadInput = ({loaded}) => {
    return (
        loaded
            ?
            <div className={'input-loader'}>
                <Spinner animation='border' variant={'primary'}/>
            </div>
            : null
    );
};

export default LoadInput;

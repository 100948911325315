import React, {useEffect, useMemo, useState} from 'react';
import { Form } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

import {
    ADDRESS,
    CHINA_TRACK,
    CITY,
    COMMENT,
    COMMODITY,
    CONSIGNEE,
    DATE, EMAIL,
    HS_CODE,
    IOSS_NUMBER,
    PASSPORT, PASSPORT_NUMBER,
    PHONE,
    PRICE,
    QTY,
    REGION,
    SEND_BY,
    SENSITIVE_GOODS,
    SKU,
    SUM_2, TAX_ID_NUMBER,
    TO_COUNTRY,
    TRACK_NUMBER,
    TRACKER,
    WAREHOUSE,
    WEIGHT,
    SERVICE_TYPE,
    ZIP,
} from "../../constants";

import InputText        from "../input/InputText";
import Switch           from "../input/Switch";
import {parsePassport}  from "../../utils/misc";
import {getCountryList} from "../../utils/api";

const FORM_ID = 'sent-parcel-form';

const SentParcelDetailForm = ({data}) => {

    let savedIOSS = false;

    const {t} = useTranslation();
    const formRef = React.createRef();

    const {
        tracknum: chinaTrack,
        code: trackNumber,
        date,
        reciver: receiver,
        tracker,
        to,
        sensitive_goods: sn,
        ship,
        quantity: qty,
        sum,
        weight,
        service_type,
        value: price,
        hs_code,
        sku,
        Commodity: commodity,
        url,
        useMeestIOSS,
        IOSS_Number,
        zip,
        region,
        city,
        address,
        email,
        passport_num,
        coment,
        debt,
        WH: wh,
        parselvalue,
        phone_number: phone,
        TaxIdNumber,
        width, length, height, volumetric_weight, estimated_weight //KK-130
    } = data;

    const [listCountriesForTypeDelivery, setListCountriesForTypeDelivery] = useState([])

    useEffect(() => {

        getCountryList().then(({data}) => {
            setListCountriesForTypeDelivery([...(data.filter( el => +el.isEurope) ? data.filter( el => +el.isEurope).map(el=>el.country_id) : []), 'UA']);
        });

    }, [])

    savedIOSS = useMemo(() => {
        return useMeestIOSS === 'true';
    }, [useMeestIOSS])

    const countryWithServiceType = useMemo(() => {
        const present = listCountriesForTypeDelivery.includes(to.country_id);
        return true;
    }, [to])

    const serviceType = useMemo(() => {
        if(service_type === "0") return t('toDoor')
        if(service_type === "1") return t('PUDO')
        return t('error');
    }, [service_type])

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    return (
        <Form ref={formRef} noValidate onSubmit={handleSubmit} className={'pb-4'}>
            <table id={FORM_ID} width={'100%'}>
                <tbody>
                <tr>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                    <td colSpan={1} className={'width-1-30'}></td>
                </tr>
                <tr>
                    <td colSpan={6}>
                        <div className={'p-2 add-new-td add-new-td-left-top add-new-td-border-radius-top'}>
                            <p className={'m-0'}>
                                <small>{t('track_number')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={TRACK_NUMBER}
                                value={trackNumber}
                                disabled
                            />
                        </div>
                    </td>
                    <td colSpan={6}>
                        <div className={'p-2 add-new-td add-new-td-top'}>
                            <p className={'m-0'}>
                                <small>{t('Domestic_express_tracking')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={CHINA_TRACK}
                                value={chinaTrack}
                                disabled
                            />
                        </div>
                    </td>
                    <td colSpan={6}>
                        <div className={'p-2 add-new-td add-new-td-top'}>
                            <p className={'m-0'}>
                                <small>{t('data_sent')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={DATE}
                                value={date}
                                disabled
                            />
                        </div>
                    </td>
                    <td colSpan={6}>
                        <div className={'p-2 add-new-td add-new-td-top'}>
                            <p className={'m-0'}>
                                <small>{t('receiver_name')}</small>
                            </p>
                            <InputText
                                id={CONSIGNEE}
                                value={receiver}
                                disabled
                            />
                        </div>
                    </td>
                    <td colSpan={6}>
                        <div className={'p-2 add-new-td add-new-td-right-top add-new-td-border-radius-right'}>
                            <p className={'m-0'}>
                                <small>{t('commodity')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={COMMODITY}
                                value={commodity}
                                disabled
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colSpan={countryWithServiceType ? 5 : 6}>
                        <div className={'p-2 add-new-td add-new-td-left'}>
                            <p className={'m-0'}>
                                <small>{t('comment')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={COMMENT}
                                value={coment}
                                disabled
                            />
                        </div>
                    </td>
                    <td colSpan={countryWithServiceType ? 5 : 6}>
                        <div className={'p-2 add-new-td'}>
                            <p className={'m-0'}>
                                <small>{t('ship')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={SEND_BY}
                                value={ship}
                                disabled
                            />
                        </div>
                    </td>
                    {
                        countryWithServiceType ?
                            <td colSpan={5}>
                                <div className={'p-2 add-new-td'}>
                                    <p className={'m-0'}>
                                        <small>{t('TypeDelivery')}</small>
                                        <InputText
                                            id={SERVICE_TYPE}
                                            value={serviceType}
                                            disabled
                                        />
                                    </p>
                                </div>
                            </td>
                            : null
                    }
                    <td colSpan={countryWithServiceType ? 5 : 6}>
                        <div className={'p-2 add-new-td'}>
                            <p className={'m-0'}>
                                <small>{t('email')}</small>
                            </p>
                            <InputText
                                id={EMAIL}
                                value={email}
                                disabled
                            />
                        </div>
                    </td>
                    <td colSpan={countryWithServiceType ? 5 : 6}>
                        <div className={'p-2 add-new-td'}>
                            <p className={'m-0'}>
                                <small>{t('goods_type')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={SENSITIVE_GOODS}
                                value={sn}
                                disabled
                            />
                        </div>
                    </td>
                    <td colSpan={countryWithServiceType ? 5 : 6}>
                        <div
                            className={'p-2 add-new-td add-new-td-right'}>
                            <p className={'m-0'}>
                                <small>{t('phone')}</small>
                            </p>
                            <InputText
                                id={PHONE}
                                value={phone}
                                disabled
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colSpan={6}>
                        <div className={'p-2 add-new-td add-new-td-left'}>
                            <p className={'m-0'}>
                                <small>{t('dest_country')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={TO_COUNTRY}
                                value={to}
                                disabled
                            />
                        </div>
                    </td>
                    {/*<td>*/}
                    {/*    <div className={'p-2 add-new-td'}>*/}
                    {/*        <p className={'m-0'}>*/}
                    {/*            <small>{t('debit')}</small>*/}
                    {/*        </p>*/}
                    {/*        <InputText*/}
                    {/*            id={'debt'}*/}
                    {/*            value={debt}*/}
                    {/*            disabled*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</td>*/}
                    <td colSpan={6}>
                        <div className={'p-2 add-new-td'}>
                            <p className={'m-0'}>
                                <small>{t('tracking')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={TRACKER}
                                value={tracker}
                                disabled
                            />
                        </div>
                    </td>
                    <td colSpan={6}>
                        <div className={'p-2 add-new-td'}>
                            <p className={'m-0'}>
                                <small>{t('passport_series')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={PASSPORT}
                                value={parsePassport(passport_num).series}
                                disabled
                            />
                        </div>
                    </td>
                    <td colSpan={6}>
                        <div className={'p-2 add-new-td'}>
                            <p className={'m-0'}>
                                <small>{t('passport_number')}</small>
                            </p>
                            <InputText
                                id={PASSPORT_NUMBER}
                                value={parsePassport(passport_num).number}
                                disabled
                            />
                        </div>
                    </td>
                    <td colSpan={6}>
                        <div className={'p-2 add-new-td add-new-td-right'}>
                            <p className={'m-0'}>
                                <small>{t('freight_cost')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={SUM_2}
                                value={sum}
                                disabled
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colSpan={6}>
                        <div className={'p-2 add-new-td add-new-td-left'}>
                            <p className={'m-0'}>
                                <small>{t('qty_2')}</small>
                            </p>
                            <InputText
                                id={QTY}
                                value={qty}
                                disabled
                            />
                        </div>
                    </td>
                    <td colSpan={6}>
                        <div className={'p-2 add-new-td'}>
                            <p className={'m-0'}>
                                <small>{t('value')}</small>
                            </p>
                            <InputText
                                id={PRICE}
                                value={price}
                                disabled
                            />
                        </div>
                    </td>
                    <td colSpan={6}>
                        <div className={'p-2 add-new-td'}>
                            <p className={'m-0'}>
                                <small>{t('weight')}</small>
                            </p>
                            <InputText
                                id={WEIGHT}
                                value={weight}
                                disabled
                            />
                        </div>
                    </td>
                    <td colSpan={6}>
                        <div
                            data-tip={t('estimateWeightTooltip')}
                            className={'p-2 '}>
                            <p className={'m-0'}>
                                <small>{t('estimateWeight')}</small>
                            </p>
                            <InputText
                                id={'estimate_weight'}
                                value={!isNaN(Number(estimated_weight)) && Number(estimated_weight) ? estimated_weight : weight}
                                disabled
                            />
                        </div>
                    </td>
                    <td colSpan={6}>
                        <div
                            data-tip={t('volumizingWeightTooltip')}
                            className={'p-2 add-new-td-right'}>
                            <p className={'m-0'}>
                                <small>{t('volumizingWeight')}</small>
                            </p>
                            <InputText
                                id={'volumizing_weight'}
                                value={volumetric_weight}
                                disabled
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colSpan={6}>
                        {
                            savedIOSS
                                ?
                                <div
                                    className={'p-2 add-new-td add-new-td-left d-flex align-items-center'}
                                    style={{height: '82px'}}
                                >
                                    <Switch
                                        id={'ioss_switch'}
                                        defaultValue={savedIOSS}
                                        labelText={t('infoIOSS')}
                                        disabled
                                    />
                                </div>
                                :
                                <div
                                    className={'p-2 add-new-td add-new-td-left'}>
                                    <p className={'m-0'}>
                                        <small>{t('ioss_number')}</small>
                                        {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                                    </p>
                                    <InputText
                                        id={IOSS_NUMBER}
                                        value={IOSS_Number}
                                        disabled
                                    />
                                </div>
                        }
                    </td>
                    <td colSpan={6}>
                        <div className={'p-2 add-new-td'}>
                            <p className={'m-0'}>
                                <small>{t('hs_code')}</small>
                            </p>
                            <InputText
                                id={HS_CODE}
                                value={hs_code}
                                disabled
                            />
                        </div>
                    </td>
                    <td colSpan={6}>
                        <div className={'p-2 add-new-td'}>
                            <p className={'m-0'}>
                                <small>{t('sku_code')}</small>
                            </p>
                            <InputText
                                id={SKU}
                                value={sku}
                                disabled
                            />
                        </div>
                    </td>
                    <td colSpan={6}>
                        <div className={'p-2 add-new-td'}>
                            <p className={'m-0'}>
                                <small>{t('tax_id_number')}</small>
                            </p>
                            <InputText
                                id={TAX_ID_NUMBER}
                                value={Array.isArray(TaxIdNumber) ? '' : TaxIdNumber}
                                disabled
                            />
                        </div>
                    </td>
                    <td colSpan={6}>
                        <div
                            data-tip={t('lwhTooltip')}
                            className={'p-2 add-new-td-right'}>
                            <p className={'m-0'}>
                                <small>{t('lwh')}</small>
                            </p>
                            <InputText
                                id={'lwh'}
                                value={`${length}*${width}*${height}`}
                                disabled
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colSpan={6}>
                        <div className={'p-2 add-new-td add-new-td-left'}>
                            <p className={'m-0'}>
                                <small>{t('zip')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={ZIP}
                                value={zip}
                                disabled
                            />
                        </div>
                    </td>
                    <td colSpan={6}>
                        <div className={'p-2 add-new-td'}>
                            <p className={'m-0'}>
                                <small>{t('region')}</small>
                            </p>
                            <InputText
                                id={REGION}
                                value={region}
                                disabled
                            />
                        </div>
                    </td>
                    <td colSpan={6}>
                        <div className={'p-2 add-new-td'}>
                            <p className={'m-0'}>
                                <small>{t('city')}</small>
                            </p>
                            <InputText
                                id={CITY}
                                value={city}
                                disabled
                            />
                        </div>
                    </td>
                    <td colSpan={12}>
                        <div className={'p-2 add-new-td add-new-td-right'}>
                            <p className={'m-0'}>
                                <small>{t('address')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={ADDRESS}
                                value={address}
                                disabled
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colSpan={24}>
                        <div className={'p-2 add-new-td add-new-td-left add-new-td-border-radius-left add-new-td-bottom'}>
                            <p className={'m-0'}>
                                <small>{t('web_link')}</small>
                                {/*<InfoQuestion className={'p-2 cursor-pointer'} text={t('tooltip_qty')}/>*/}
                            </p>
                            <InputText
                                id={URL}
                                value={url}
                                disabled
                            />
                        </div>
                    </td>
                    <td colSpan={6}>
                        <div
                            className={'p-2 add-new-td add-new-td-bottom add-new-td-right add-new-td-border-radius-bottom'}>
                            <p className={'m-0'}>
                                <small>{t('wh_short')}</small>
                            </p>
                            <InputText
                                id={WAREHOUSE}
                                value={wh}
                                disabled
                            />
                        </div>
                    </td>
                </tr>
                </tbody>
                <tr><td>
                    <ReactTooltip
                        effect={'solid'}
                        type={'light'}
                        place={'bottom'}
                        delayHide={100}
                        // border={'1px solid black'}
                        className={'my-tooltip sent-parcel'}
                    />
                </td></tr>
            </table>
        </Form>
    );
};

export default SentParcelDetailForm;

import React, { memo } from 'react';

import HeaderLarge   from "./HeaderLarge";
import HeaderSmall   from "./HeaderSmall";

const Header = () => {

    return (
        <header className={'container-fluid'}>
            <HeaderLarge className={'d-none d-xl-flex'}/>
            <HeaderSmall className={'d-xl-none'}/>
        </header>
    );
};

export default memo(Header);

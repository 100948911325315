import React, { useEffect, useState }                                        from 'react';
import { useDispatch }                                                       from "react-redux";
import { Button }                                                            from "react-bootstrap";

import { setLoad, setLoadSpinner }                                           from "../../../storage/global";
import { getWarningNotificationEmails, saveWarningNotificationEmails }       from "../../../utils/api";
import { viewAlert }                                                         from "../../../utils/misc";
import InputText                                                             from "../../../components/input/InputText";
import uniqueArray                                                           from "../../../utils/uniqueArray";

const NOTIFICATION_EMAIL = 'notification-email'

const RequestStatisticNotif = () => {

    const dispatch = useDispatch();

    const [warningNotificationEmails, setWarningNotificationEmails] = useState({
        warningNotificationEmails: [],
        rootWarningNotificationEmails: []
    })
    const [world, setWorld] = useState('');
    
    useEffect(() => {
        dispatch(setLoad(false))

        getNotificationEmails()
    }, [])

    function getNotificationEmails() {
        dispatch(setLoadSpinner(true))
        return getWarningNotificationEmails()
            .then(response => setWarningNotificationEmails({warningNotificationEmails: [...response.data], rootWarningNotificationEmails: [...response.data]}))
            .catch(({response}) => {viewAlert(dispatch, response)})
            .finally(() => dispatch(setLoadSpinner(false)))
    }

    function addNotificationEmails(event) {
        const input = document.getElementById(NOTIFICATION_EMAIL)
        setWarningNotificationEmails({
            ...warningNotificationEmails,
            warningNotificationEmails: uniqueArray([...warningNotificationEmails.warningNotificationEmails, input.value.toString().toUpperCase()])
        })
        setWorld('')
    }

    function removeNotificationEmails(value) {
        setWarningNotificationEmails({...warningNotificationEmails, warningNotificationEmails: warningNotificationEmails.warningNotificationEmails.filter(v => v !== value)})
    }

    function reset() {
        setWarningNotificationEmails({...warningNotificationEmails, warningNotificationEmails: [...warningNotificationEmails.rootWarningNotificationEmails]})
    }

    async function saveData() {
        dispatch(setLoadSpinner(true))
        try {
            const responseDangerWorlds = await saveWarningNotificationEmails(warningNotificationEmails);
            viewAlert(dispatch, {responseDangerWorlds})
        } catch(e) {
            viewAlert(dispatch, {"err": e})
        }
        dispatch(setLoadSpinner(false))
    }

    function handleValues(value, name, inputRef) {
        switch (name) {
            case NOTIFICATION_EMAIL:
                setWorld(value)
                break;
            default:
                break;
        }
    }

    return (
        <div className={''}>
            <div className={'mt-3'}>
                <div className={'mt-3 mt-lg-0'}>
                    <div className={'p-3'} style={{
                        border: '1px solid red',
                        borderRadius: '15px',
                        height: 'auto',
                        maxHeight: '20rem',
                        overflow: "auto",
                        backgroundColor: '#FFF'
                    }}>
                        {
                            warningNotificationEmails.warningNotificationEmails.map((v, k) => {
                                return <div key={k} className={'position-relative'} style={{
                                    border: '1px solid blue',
                                    borderRadius: '10px',
                                    padding: '5px',
                                    margin: '5px',
                                    display: 'inline-block'
                                }}>
                                    <strong>{v}</strong>
                                    <Close onClick={event => removeNotificationEmails(v)}/>
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className={'mt-3'}>
                    <div className={'row'}>
                        <div className={'col'}>
                            <InputText handleChange={handleValues} value={world} id={NOTIFICATION_EMAIL}
                                       placeholder={'Введіть пошту'} closeButton/>
                        </div>
                        <div className={'col-auto'}>
                            <Button onClick={addNotificationEmails}>Добавить</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'row mt-3'}>
                <div className={'col-6'}>
                    <Button onClick={reset} className={'w-100'} variant={'outline-primary'}>Скинути</Button>
                </div>
                <div className={'col-6'}>
                    <Button onClick={saveData} className={'w-100'} variant={'primary'}>Зберегти</Button>
                </div>
            </div>
        </div>
    );
};

export default RequestStatisticNotif;

function Close(props) {
    return (
        <div {...props} className={'cursor-pointer position-absolute'} style={{
            top: 0,
            right: '10px',
        }}>
            <div style={{
                width: '15px',
                height: '2px',
                backgroundColor: 'red',
                transform: 'rotate(45deg)',
                position: 'absolute',
            }}/>
            <div style={{
                width: '15px',
                height: '2px',
                backgroundColor: 'red',
                transform: 'rotate(135deg)',
                position: 'absolute',
            }}/>
        </div>
    )
}

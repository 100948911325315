import React, { useEffect }         from 'react';
import { useDispatch }              from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Outlet }                   from "react-router";

import Logo                         from "../../components/shared/Logo";
import MenuItemGroup                from "../../components/menu/MenuItemGroup";
import CopyrightMenu                from "../../components/shared/CopyrightMenu";
import { setLoad, setShowMenu }     from "../../storage/global";
import { routesNames }              from "../../constants";

const Docs = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentLocation = useLocation().pathname;

    const navList = [
        {
            active: `/${routesNames.DOCS}/${routesNames.API}` === currentLocation,
            title: routesNames.API.toUpperCase(),
            ico: {
                src: `/assets/img/new-parcel-light.svg`,
                alt: 'shopping'
            },
            handle: () => goTo(`${routesNames.DOCS}/${routesNames.API}`),
        },
        {
            active: `/${routesNames.DOCS}/${routesNames.APP}` === currentLocation,
            title: routesNames.APP.toUpperCase(),
            ico: {
                src: `/assets/img/new-parcel-light.svg`,
                alt: 'shopping'
            },
            handle: () => goTo(`${routesNames.DOCS}/${routesNames.APP}`),
        },
        {
            active: `/${routesNames.DOCS}/${routesNames.COMPONENTS}` === currentLocation,
            title: routesNames.COMPONENTS.toUpperCase(),
            ico: {
                src: `/assets/img/new-parcel-light.svg`,
                alt: 'shopping'
            },
            handle: () => goTo(`${routesNames.DOCS}/${routesNames.COMPONENTS}`),
        },
    ];

    useEffect(() => {
        dispatch(setLoad(false))
    })

    function goTo(path) {
        navigate(`/${path}`)
        dispatch(setShowMenu(false))
    }

    function goToHome() {
        goTo(`${routesNames.DOCS}`)
    }

    return (
        <div className={'v-application h-100'}>
            <aside
                className={'background-primary aside-large'}
            >
                <Logo goTo={goToHome} id={'logo-large'} type={'white'} className={'d-flex justify-content-center align-items-center'}/>
                <MenuItemGroup navList={navList} theme={'light'}/>
                <CopyrightMenu/>
            </aside>
            <main className={'h-100'}>
                <Outlet/>
            </main>
        </div>
    );
};

export default Docs;

import React           from 'react';
import { useSelector } from "react-redux";

import { setCookie }   from "../../utils/cookie";

export const EN = "en";
export const CN = "cn";

export const langList = [EN, CN];

const LangBlock = ({className, style}) => {

    const globalLocale = useSelector(state => state.global.locale)

    const setLang = (locale) => {
        setCookie('language', locale, {'max-age': 31536000}) //age - one year
        document.location.reload()
    }
    const setLangEn = () => {
        const lang = EN;
        if (globalLocale !== lang)
            setLang(lang)
    }
    const setLangCn = () => {
        const lang = CN;
        if (globalLocale !== lang)
            setLang(lang)
    }

    return (
        <div className={`lang ${className ?? ''}`} style={{...style}}>
            <a href='#'>
                <span onClick={setLangEn} className={`${globalLocale === 'en' ? 'active-lang' : ''}`}>En</span>
            </a>
            <a  href='#'>
                <span onClick={setLangCn} className={`${globalLocale === 'cn' ? 'active-lang' : ''}`}>中文</span>
            </a>
        </div>
    );
};

export default LangBlock;

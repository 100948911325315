import React              from 'react';
import { Form }           from "react-bootstrap";

const Switch = ({id, labelText, handle, defaultValue, isInvalid, className, styles, disabled}) => {

    const switchRef = React.createRef();

    function onChange(event) {
        if (typeof handle === 'function') {
            handle(event.currentTarget.checked, id, switchRef)
        }
    }

    return (
        // <Form itemRef={switchRef}>
            <Form.Check
                className={`${className ? className : ''}`}
                style={{...styles}}
                ref={switchRef}
                onChange={onChange}
                type="switch"
                id={id}
                label={labelText}
                defaultChecked={defaultValue}
                isInvalid={isInvalid}
                disabled={disabled}
            />
        // </Form>
    );
};

export default Switch;

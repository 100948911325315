import React, { useState } from 'react';

const PageMenu = ({className, children, stylesMenuList}) => {

    const [showMenu, setShowMenu] = useState(false)

    function openMenu() {
        setShowMenu(!showMenu);
    }

    return (
        <div className={`${className ? className : ''} page-menu cursor-pointer d-mobile ${showMenu ? 'page-menu-active' : ''}`}>
            <div className={'w-100 h-100 position-relative'}>
                <div onClick={openMenu} className={'h-100 w-100'}>
                    <div id={'dot-1'}/>
                    <div id={'dot-2'}/>
                    <div id={'dot-3'}/>
                </div>
                <div style={{
                    position: 'absolute',
                    bottom: '60px',
                    right: '0',
                    borderRadius: '16px',
                    padding: '15px',
                    boxShadow: '0px 4px 24px rgba(23, 23, 37, 0.12)',
                    background: '#FFFFFF',
                    ...stylesMenuList,
                }} className={`d-flex flex-column justify-content-around list-page-menu ${showMenu ? '' : 'opacity-0 visibility-hidden'}`}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default PageMenu;

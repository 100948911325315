import React, {memo, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Outlet, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {
    setAlert,
    setBalance,
    setBalanceFree,
    setBalanceNotPaid,
    setInsuranceCountres,
    setlostParsels,
    setIsMobile,
    setLoad,
    setModalForm,
    setProfile,
    setShowMenu
} from "../storage/global";

import {COMPANY_NAME, CONTACT_NAME, EMAIL, IC_CRD_NUM, PHONE, routesNames, WECHAT_ID} from "../constants";

import Header from "../components/header/Header";
import Menu from "../components/menu/Menu";
import PollForm from "../components/forms/PollForm";
import {
    getBalance,
    getExcludedGoods,
    getInsuranceCountry,
    getLastActiveQuestionTemplate,
    getNotificationSettings,
    getNotPaidParcels,
    getProfileSettings,
    getUserInfo,
    getLostParsels,
    loadStatuses,
} from "../utils/api";
import {checkValidate, viewAlert }                from "../utils/misc";
import { noEmptyValidate } from "../utils/validate";
import axios from "../lib/axios";
import ProfileForm, { getValidationsSet } from "../components/forms/ProfileForm";

const RootApp = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {t} = useTranslation();

    const load = useSelector(state => state.global.load);
    const showMenu = useSelector(state => state.global.showMenu);
    const isMobile = useSelector(state => state.global.isMobile);

    const [intervalId, setIntervalId] = useState('');

    useEffect(() => {

        setIntervalId(setInterval(() => {
            if (isMobile !== (window.innerWidth < 1200)) dispatch(setIsMobile(window.innerWidth < 1200))
        }, 1000))

        dispatch(setLoad(true))

        loadStatuses(dispatch);

        let uid = '';
        let profileInfo = {};
        let notificationSettings = {};
        getUserInfo()
            .then(response => {
                uid = response.data.useruid;
                dispatch(setProfile(response.data));
                profileInfo = response.data;
                return {profileInfo};
            })
            .then(async ({profileInfo}) => {
                const data = await getNotificationSettings().then(({data}) => data);
                notificationSettings = data;
                return {profileInfo, notificationSettings: data}
            })
            .then(async ({profileInfo, notificationSettings}) => {
                await getNotPaidParcels()
                    .then(({data}) => {
                        dispatch(setBalanceNotPaid(data))
                        if (notificationSettings?.debtNotice) {
                            if (Number(data.debt) > 0) {
                                dispatch(setAlert({
                                    text: t('noPaidNotification')
                                        .replace(/(\$number)/, data.count)
                                        .replace(/(\$amount)/, data.debt)
                                        .replace(/(\$currency)/, profileInfo.currency),
                                    type: 'danger'
                                }))
                            }
                        }
                    })
                    .catch(() => {
                    })
                    .finally()
                return {notificationSettings}
            })
            .then(() => {
                //preload long request
                getExcludedGoods();
            })
            .then(() => {
                getBalance()
                    .then(response => {
                        dispatch(setBalance(response.data?.balance?.item?.bal ?? ''))
                        dispatch(setBalanceFree(response.data?.balance?.item?.bal_free ?? ''))
                    })
                    .catch(({response}) => {
                        viewAlert(dispatch, response)
                    })
            })
            .then(() => {
                getLastActiveQuestionTemplate()
                    .then((res) => {
                        if (res.status === 200 && res.data) {
                            dispatch(setModalForm({
                                title: '',
                                form: <PollForm poll={res.data}/>,
                                fullScreen: true,
                                hideOut: false,
                                styles: {
                                    zIndex: 1
                                }
                            }))
                        }
                    })
                    .catch(({response}) => {
                        console.log('getLastActiveQuestionTemplate catch: ', response)
                    })
            })
            .then(() => {
                getInsuranceCountry()
                    .then((res) => {
                        if (res.status === 200 && res.data) {
                            dispatch(setInsuranceCountres(res.data));
                        }
                    })
                    .catch(({response}) => {
                        console.log('getInsuranceCountry catch: ', response)
                    })
            })
            .then(() => {
                getLostParsels({id: uid})
                    .then((res) => {
                        if (res.status === 200 && res.data) {
                            dispatch(setlostParsels(res.data));
                        }
                        if (res.data.length && notificationSettings?.parcelsNotice) {
                            const trList = res.data.map( (el) => el.TrackNumber).join(', ')
                            dispatch(setAlert({
                                text: t('lostParcelsNotification')
                                    .replace(/(\$trackNumbers)/, trList),
                                type: 'danger'
                            }))
                        }
                    })
                    .catch(({response}) => {
                        console.log('getLostParsels catch: ', response)
                    })
            })
            .then(() => {
                //profile settings
                getProfileSettings()
                    .then((res) => {
                        const {fieldsChecked, check_profile, mandatory_filling} = res.data || {fieldsChecked: '', check_profile: '', mandatory_filling: ''};

                        const validations = getValidationsSet(t);

                        const {
                            companyName,
                            email,
                            idCardNumber,
                            phoneNumber,
                            wechatId,
                            yourName,
                        } = fieldsChecked || {}

                        if (companyName) {
                            validations[COMPANY_NAME].unshift(noEmptyValidate(t('validEmpty')));
                        }
                        if (email) {
                            validations[EMAIL].unshift(noEmptyValidate(t('validEmpty')));
                        }
                        if (idCardNumber) {
                            validations[IC_CRD_NUM].unshift(noEmptyValidate(t('validEmpty')));
                        }
                        if (phoneNumber) {
                            validations[PHONE].unshift(noEmptyValidate(t('validEmpty')));
                        }
                        if (wechatId) {
                            validations[WECHAT_ID].unshift(noEmptyValidate(t('IncorrectWeChatId')));
                        }
                        if (yourName) {
                            validations[CONTACT_NAME].unshift(noEmptyValidate(t('validEmpty')));
                        }

                        const errors = [
                            {empty: !profileInfo.company_name, ...checkValidate(profileInfo.company_name || '', validations[COMPANY_NAME])},
                            {empty: !profileInfo.contact_name, ...checkValidate(profileInfo.contact_name || '', validations[CONTACT_NAME])},
                            {empty: !profileInfo.phone, ...checkValidate(profileInfo.phone || '', validations[PHONE])},
                            {empty: !profileInfo.ic_crd_num, ...checkValidate(profileInfo.ic_crd_num || '', validations[IC_CRD_NUM])},
                            {empty: !profileInfo.wechat, ...checkValidate(profileInfo.wechat || '', validations[WECHAT_ID])},
                            {empty: !profileInfo.username, ...checkValidate(profileInfo.username || '', validations[EMAIL])},
                        ];

                        const withErrorField = errors.find(value => value.isError || value.empty);

                        if (check_profile && withErrorField) {
                            dispatch(setModalForm({
                                title: t('profile'),
                                form: <ProfileForm/>,
                                hideOut: !mandatory_filling,
                                hideCloseButton: !!mandatory_filling,
                                formContent: {classNames: 'modal-form-content-profile'},
                            }))
                        }
                    })
                    .catch()
            })
            .catch(({response}) => {
                if (response.status === 401) {
                    navigate(`/${routesNames.AUTH}/${routesNames.LOGIN}`)
                    return
                }
                viewAlert(dispatch, response)
                dispatch(setLoad(false))
            })
            .finally(() => {
                dispatch(setLoad(false))
            })

        return () => {
            dispatch(setProfile({}))
            dispatch(setBalance(''))
            clearInterval(intervalId)
        }
    }, [])

    function hideSmallMenu() {
        dispatch(setShowMenu(false))
    }

    return (
        <>
            {
                !load
                    ?
                    <div className={'h-100 w-100'}>
                        <Menu/>
                        <main className={'h-100'}>
                            <Header/>
                            <div>
                                <Outlet/>
                            </div>
                        </main>
                        <div
                            onClick={hideSmallMenu}
                            className={`background-small-menu ${showMenu ? 'opacity-1 z-index-unset' : ''}`}
                        />
                    </div>
                    : null
            }
        </>
    );
};

export default memo(RootApp);

import React      from 'react';
import { Button } from "react-bootstrap";

const ButtonSmallNoBorder = ({classNames, children, variant}) => {

    return (
        <Button className={`p-1 ${classNames ?? ''}`} variant={`${variant ?? 'outline-primary-no-border'}`}>
            {children}
        </Button>
    );
};

export default ButtonSmallNoBorder;

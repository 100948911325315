
import React, { useEffect, useMemo, useState }  from 'react';
import { useDispatch, useSelector }             from "react-redux";
import { useTranslation }                       from "react-i18next";

import {
    setAlertShow,
    setList,
    setModalForm,
    setSearch,
    setSelectedList,
    setTokenInfo,
    setTrackerList,
    setGroupSearchesCheckboxes, setLoadSpinner
} from "../../storage/global";

import {
    COMMODITY,
    CONSIGNEE,
    SENT_DATE,
    DESC,
    PRICE,
    COMENT,
    QTY,
    SEND_BY,
    TO_COUNTRY,
    TRACK_NUMBER,
    WEIGHT,
    BARCODE,
    TRACKER,
    TELEPHONE,
    USER_PHONE,
    RECIPIENT_PHONE,
    SERVICE_TYPE,
    SELECTED
} from "../../constants";

import ActionBlock                         from "./ActionBlock";
import PrintSettingsForm                   from "../../components/forms/PrintSettingsForm";
import DeleteParcelsForm                   from "../../components/forms/DeleteParcelsForm";
import PageListTemplate                    from "../../components/shared/PageListTemplate";
import PageMenu                            from "../../components/shared/PageMenu";
import DeleteButton                        from "../../components/shared/pages_buttons/DeleteButton";
import PrintStickerButton                  from "../../components/shared/pages_buttons/PrintStickerButton";
import ButtonStyleAction                   from "../../components/shared/pages_buttons/ButtonStyleAction";
import WaitingArrivalDetailForm            from "../../components/forms/WaitingArrivalDetailForm";
import CheckBox                            from "../../components/input/CheckBox";
import CheckboxList                        from "../../components/shared/CheckboxList";

import {deleteWaitingArrivalParcels, getParcelInfo, getTrackers, getWaitingArrivalParcels} from "../../utils/api";
import {goTrackingSystem, requestDeleteMethod, viewAlert} from "../../utils/misc";
import NewParcelForm from "../../components/forms/NewParcelForm";
import EditButton from "../../components/shared/pages_buttons/EditButton";

const WaitingArrival = () => {

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const order = {
        direction: DESC,
        name: SENT_DATE
    }

    const list = useSelector(state => state.global.list);
    const groupSearchesCheckboxes = useSelector(state => state.global.groupSearchesCheckboxes.waitingArrival);
    const withGroupFilter = useSelector(state => state.global.groupSearchesCheckboxes.withGroupFilter);

    const [checkedAll, setCheckedAll] = useState(false);
    const [listAfterFilter, setListAfterFilter] = useState([]);

    const deleteFormData = {
        title: t('removeQuestion'),
        form: <DeleteParcelsForm
            deleteMethod={requestDeleteMethod(dispatch, getList, deleteWaitingArrivalParcels, t)}
            text={t('removeFromShipmentQuestion')}
        />,
    }

    //Clear before destroy component
    useEffect(() => () => {
        dispatch(setModalForm({show: false}))
        dispatch(setAlertShow(false))
        dispatch(setSearch(''))
        dispatch(setList([]))
        dispatch(setSelectedList([]))
        dispatch(setTrackerList([]))
    }, [])

    const groupSearchesCheckboxesCodes = useMemo(() => Object.keys(groupSearchesCheckboxes).filter( code => groupSearchesCheckboxes[code]), [groupSearchesCheckboxes])

    function handlePrint() {
        dispatch(setModalForm({
            title: t('printSettings'),
            form: <PrintSettingsForm/>,
            fullScreen: true,
        }))
    }

    function handleEdit(parcel) {

        const uid = parcel.uid;

        dispatch(setLoadSpinner(true))
        //makeRequest
        getParcelInfo({uid})
            .then(({data}) => {
                dispatch(setModalForm({
                    title: '',
                    form: <NewParcelForm data={{...parcel, ...data.item}}
                                         isEdit
                                         getListParcels={getList}
                                         disableFields={{
                                             destinationCountry: true
                                         }}
                                         page={'waiting'}
                    />,
                    fullScreen: true,
                    hideOut: false,
                }))
            })
            .catch(({response}) => {
                viewAlert(dispatch, response, {title: t('error')})
            })
            .finally(() => dispatch(setLoadSpinner(false)))
    }

    function openGroupSearch() {
      dispatch(setModalForm({
          title: t('GroupSearchMenu'),
          form: <CheckboxList list={groupSearchesCheckboxes} setList={saveAndApplyGroupSearch}/>
      }))
    }

    function saveAndApplyGroupSearch(list) {
      dispatch(setGroupSearchesCheckboxes({
          page: 'waitingArrival',
          val: list
      }))
    }

    function handleSearch(search) {
        dispatch(setSelectedList([]))
    }

    function orderedHook(parcels) {
        if (parcels && parcels.length) {
            const trackersFromLocalStorageJSON = localStorage.getItem('trackers');
            let trackers = {};
            let expire = 0;
            const currentTimestamp = new Date().getTime();
            if (trackersFromLocalStorageJSON) {
                const trackersFromLocalStorage = JSON.parse(trackersFromLocalStorageJSON)
                if (trackersFromLocalStorage) {
                    expire = Number(trackersFromLocalStorage.expire);
                    const isExpire = currentTimestamp > expire
                    if (!isExpire) {
                        trackers = {...trackersFromLocalStorage.list};
                    }
                }
            }

            const loadCodes = [];
            for (let index = 0; index < parcels.length; index++) {
                const parcel = parcels[index];

                if (!trackers[parcel.barcode] || trackers[parcel.code].eventDescr_descrEN === "No status") {
                    loadCodes.push(parcel.code);
                }
            }
            loadCodes.length && getTrackers(loadCodes, dispatch, t('noStatus'));
        }
    }

    function handleDoubleClick(event, parcel, ref) {
        dispatch(setModalForm({
            title: 'Parcel details',
            form: <WaitingArrivalDetailForm data={parcel}/>,
            fullScreen: true,
            hideOut: true,
        }))
    }

    function handleAllChek(value) {
        const newList = [...list];
        (listAfterFilter && listAfterFilter.length ? listAfterFilter : list).forEach(parcel => {
            const numEl = list.findIndex( el => el.uid === parcel.uid)
            if (numEl >= 0) newList[numEl].check = value;
        });
        dispatch(setList(newList));
        setCheckedAll(value)
    }

    function selectAll() {
        handleAllChek(!checkedAll)
    }

    function handleCheck(value, item, ref) {
        dispatch(setList(list.map(parcel => {
            if (item.uid === parcel.uid) {
                parcel.check = !parcel.check;

            }
            return parcel;
        })));
    }

    useMemo(() => {
        const isCheck:boolean = !!list.filter(parcel => parcel.check).length
        setCheckedAll(isCheck)
    }, [list])

    const listHead = [
        {
            name: SELECTED, col: 1, text: '+', sortArrow: false, getChildren: (v, k) => {
                return <CheckBox
                    id={'all-check'}
                    className={'check-input'}
                    styles={{fontSize: '20px'}}
                    handle={handleAllChek}
                    value={checkedAll}
                />
            }
        },
        {name: BARCODE, col: 2, text: t('Domestic_express_tracking')},
        {name: TRACK_NUMBER, col: 3, text: t('track_number')},
        {name: SENT_DATE, col: 2, text: t('dispatch_date')},
        {name: COMMODITY, col: 2, text: t('commodity')},
        {name: TRACKER, col: 2, text: t('tracking')},
        {name: SERVICE_TYPE, col: 2, text: t('TypeDelivery')},
        // {name: QTY, col: 1, text: t('qty_2')},
        {name: WEIGHT, col: 2, text: t('weight')},
        // {name: PRICE, col: 1, text: t('value')},
        {name: SEND_BY, col: 2, text: t('ship')},
        {name: TO_COUNTRY, col: 2, text: t('to')},
        {name: CONSIGNEE, col: 2, text: t('consignee')},
        // {name: TELEPHONE, col: 2, text: t('telephone')},
        {name: COMENT, col: 2, text: t('comment')},
    ]

    const listItem = [
        {
            name: SELECTED, col: 1, isTrackNumber: false,
            getChildren: (parcel) => {
                return <CheckBox
                    className={'check-input'}
                    styles={{fontSize: '20px'}}
                    handle={(value, id, ref) => handleCheck(value, parcel, ref)}
                    value={!!parcel.check}
                />
            }
        },
        {name: TRACK_NUMBER, col: 2, isTrackNumber: false},
        {name: BARCODE, col: 3, isTrackNumber: true,
            click: (event, parcel, ref) => {goTrackingSystem(parcel.barcode)}, attr: {"no-select": 'no-select'},
            copy: true,
        },
        {name: SENT_DATE, col: 2, isTrackNumber: false},
        {name: COMMODITY, col: 2, isTrackNumber: false},
        {name: TRACKER, col: 2, isTrackNumber: false, afterLoadData: true},
        {
            name: SERVICE_TYPE,
            col: 2,
            isTrackNumber: false,
            getChildren: (parcel) => {
                if(parcel.service_type === 0) return t('toDoor')
                if(parcel.service_type === 1) return t('PUDO')
                return t('error')
            }
        },
        // {name: QTY, col: 1, isTrackNumber: false},
        {name: WEIGHT, col: 2, isTrackNumber: false},
        // {name: PRICE, col: 1, isTrackNumber: false},
        {name: SEND_BY, col: 2, isTrackNumber: false},
        {name: TO_COUNTRY, col: 2, isTrackNumber: false},
        {name: CONSIGNEE, col: 2, isTrackNumber: false},
        // {name: RECIPIENT_PHONE, col: 2, isTrackNumber: false},
        {name: COMENT, col: 2, isTrackNumber: false},
    ]

    const listItemMobile = {
        firstColumn: [
            {name: BARCODE, nameColumn: t('track_number'), col: 24, isTrackNumber: true,
                click: (event, parcel, ref) => {goTrackingSystem(parcel.barcode)}, attr: {"no-select": 'no-select'},
                copy: true,
            },
            {name: COMMODITY, nameColumn: t('commodity'), col: 24, isTrackNumber: false},
            {name: TO_COUNTRY, nameColumn: t('to'), col: 24, isTrackNumber: false},
            {name: TRACKER, nameColumn: t('tracking'), col: 12, isTrackNumber: false, afterLoadData: true},
            {
                name: SERVICE_TYPE,
                nameColumn: t('TypeDelivery'),
                col: 12,
                isTrackNumber: false,
                getChildren: (parcel) => {
                    if(parcel.service_type === 0) return <div className={``}>
                        {t('toDoor')}
                    </div>
                    if(parcel.service_type === 1) return <div className={``}>
                        {t('PUDO')}
                    </div>
                    return <div className={``}>
                    {t('error')}
                </div>
                }
            },
        ],
        secondColumn: [
            {name: SENT_DATE, nameColumn: t('dispatch_date'), col: 14, isTrackNumber: false},
            {
                name: SELECTED, nameColumn: t(''), col: 10, isTrackNumber: false, classTableItem: 'w-100',
                getChildren: (parcel) => {
                    return <div className={'d-flex justify-content-end'}>
                        <CheckBox
                            className={'check-input'}
                            styles={{fontSize: '20px'}}
                            handle={(value, id, ref) => handleCheck(value, parcel, ref)}
                            value={!!parcel.check}
                        />
                    </div>
                }
            },
            {name: QTY, nameColumn: t('qty_2'), col: 8, isTrackNumber: false},
            {name: WEIGHT, nameColumn: t('weight'), col: 8, isTrackNumber: false},
            {name: SEND_BY, nameColumn: t('ship'), col: 8, isTrackNumber: false},
            {name: CONSIGNEE, nameColumn: t('consignee'), col: 24, isTrackNumber: false},
        ]
    }

    function getList() {
        return getWaitingArrivalParcels()
            .then(response => {
                const data = response.data.map(v => {
                    switch (Number(v.sensitive_goods)) {
                        case 0:
                            v.sensitive_goods = t('simple_goods');
                            break;
                        case 1:
                            v.sensitive_goods = t('sensitive_goods');
                            break;
                        case 2:
                            v.sensitive_goods = t('pure_buttery');
                            break;
                        default:
                            break;
                    }

                    return v;
                })
                dispatch(setList(data, t('noStatus')))
                orderedHook(data)
                return response;
            })
    }

    function setDataAfterFilter(data) {
        setListAfterFilter([...data])
    }

    return (
        <div className={'container-page h-100'}>

            <ActionBlock
                handleSearch={handleSearch}
                handlePrint={handlePrint}
                openGroupSearch={openGroupSearch}
                deleteFormData={deleteFormData}
                handle={selectAll}
                handleEdit={handleEdit}
                isSelectedAll={checkedAll}
            />

            <PageListTemplate
                srcOrder={order}
                // classesRow={'cursor-pointer'}
                uniqueRowId={'uid'}
                listHead={listHead}
                listItem={listItem}
                listItemMobile={listItemMobile}
                emptyListText={t('noParcelsWaitingArrival')}
                disableSelect
                getDataMethod={getList}
                handleDoubleClick={handleDoubleClick}
                listForFilter={withGroupFilter ? groupSearchesCheckboxesCodes : null}
                orderedHook={ data => setDataAfterFilter(data)}
            />
            <PageMenu stylesMenuList={{width: '15rem'}}>
                <EditButton handle={handleEdit}/>
                <DeleteButton deleteFormData={deleteFormData}>
                    {t('remove_from_shipment')}
                </DeleteButton>
                <PrintStickerButton handle={handlePrint}/>
                <ButtonStyleAction handle={openGroupSearch} text={'GroupSearch'} iconClasses={'fa-search mr-1'}/>
            </PageMenu>
        </div>
    );
};

export default WaitingArrival;

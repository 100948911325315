import React from 'react';
import {useSelector} from "react-redux";

import localStyles from './faq.module.scss'
import {t} from "i18next";

const Faq = () => {

    const isMobile = useSelector(state => state.global.isMobile);
    const lightTheme = !isMobile;

    return (
        <a href="https://cn.meest.cn/en/faq" target={'_blank'}
           className={`${localStyles.container} cursor-pointer nav-item  ${lightTheme ? 'my-text-white' : 'my-text-primary'}`}
             style={{
                 position: isMobile ? 'unset' : 'fixed',
                 bottom: 30,
                 width: 220
             }}
        >
            <img className={'mr-1'} src={`/assets/img/info-small-${lightTheme ? 'light' : 'dark'}.svg`} alt=""/>
            <span>
                    {t('faq')}
            </span>
        </a>
    );
};

export default Faq;

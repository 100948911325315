import React from 'react';

const Copyright = () => {
    return (
        <p className={'position-absolute text-center w-100'} style={{
            bottom: '-80px',
            fontSize: '16px',
            lineHeight: '20px',
            color: '#8493A8',
            fontWeight: 'bold'
        }}>粤ICP备16072123号</p>
    );
};

export default Copyright;

import React from 'react';

const CloseInputButton = ({clear, closeButton, classNames}) => {
    return (
        <div
            onClick={clear}
            className={`clear-search-btn cursor-pointer ${closeButton ? 'clear-search-btn-show' : ''} ${classNames}`}
        >
            <img src="/assets/img/close-blue.svg" alt="clear-search"/>
        </div>
    );
};

export default CloseInputButton;

import React, { useEffect, useState } from 'react';
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import localStyles from './RegisterForm.module.scss'

import {
    DEFAULT_ERROR_OBJECT,
    PHONE,
} from "../../constants";
import { modifyPhoneNumber } from "../../utils/misc";
import { sendSms, checkCode } from "../../utils/api";
import InputDigits from "../input/InputDigits";
import ProgressSpinner from "../shared/ProgressSpinner";

const PhoneVerify = ({
    errorMessage: errorMessagePhone,
    placeholder,
    classes,
    handleChange,
    value: phone,
    isRestorePassword,
    contactIsPhone
}) => {

    const {t, i18n} = useTranslation();

    const [timer, setTimer] = useState(0)
    const [showTimer, setShowTimer] = useState(false)
    const [disabledInput, setDisabledInput] = useState(true)
    const [disabled, setDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [verifyCode, setVerifyCode] = useState('')
    const [connectionId, setConnectionId] = useState('')
    const [isVerify, setIsVerify] = useState(false)
    const [errorMessage, setErrorMessage] = useState({...DEFAULT_ERROR_OBJECT})

    useEffect(() => {
        const id = setInterval(() => {
            if (timer - 1 >= 0)
                setTimer(timer - 1)
            else {
                if (!isVerify) {
                    setShowTimer(false)
                }
            }
        }, 1000)

        return () => {
            clearInterval(id);
        }
    }, [timer])

    useEffect(() => {
        setTimer(0);
        setShowTimer(false);
        setDisabled(false);
        setIsLoading(false);
        setVerifyCode('');
        setIsVerify(false);
        setErrorMessage({...DEFAULT_ERROR_OBJECT});
    }, [phone])

    useEffect(() => {
        if (verifyCode && verifyCode.length === 4) {
                checkCode(connectionId, verifyCode)
                    .then(({isValid, data, status}) => {
                        if(isValid) {
                            setShowTimer(false)
                            setDisabled(true)
                            setIsVerify(true)
                            handleChange(true, connectionId, verifyCode)
                            setErrorMessage({...DEFAULT_ERROR_OBJECT});
                        }
                    })
                    .catch((err) => {
                        console.log("🚀 checkCode err:", err);
                        setErrorMessage({
                            isError: true,
                            message: t('checkCode err')
                        });
                    });
        } else if (verifyCode && verifyCode.length !== 4) {
            setErrorMessage({
                isError: true,
                message: t('verification_code_incorrect')
            });
        }
    }, [verifyCode])

    function sendCodeBySms() {

        setIsLoading(true)

        setShowTimer(false)
        const reqData = isRestorePassword ?
            (contactIsPhone ? {language: i18n.language === 'cn' ? 'cn' : 'en', phone} : {language: i18n.language === 'cn' ? 'cn' : 'en', email: phone})
            : {phone};
        const req = sendSms(reqData, isRestorePassword);
        req.then(({data, status}) => {
                const {connection_id, error} = data || {};
                setConnectionId(connection_id || '');
                if (connection_id) {
                    setTimer(59)
                    setShowTimer(true)
                    setDisabledInput(false);
                    setErrorMessage({
                        isError: false,
                        message: ``
                    });
                } else
                if (error) {
                    const {code, description} = error || {};
                    const aaa = !description ? '' : description.split('; ')
                    const descriptionText = description?.split('; ').find( str => str.includes('description'))?.split(': ')[1];
                    setErrorMessage({
                        isError: true,
                        message: descriptionText || description
                    });
                    setDisabledInput(true);
                } else if (status === 200) {
                    console.log("🚀 sendCodeBySms data:", status);
                    console.log("🚀 sendCodeBySms data:", data);
                } else {
                    setErrorMessage({
                        isError: true,
                        message: `status ${status}`
                    });
                }
            })
            .finally(() => {
                setIsLoading(false)
            });
    }

    return (
        phone &&
        <div className={`d-flex align-items-center justify-content-between ${classes} ${localStyles.phoneVerifyContainer}`}>
            <InputDigits disabled={disabled || disabledInput || errorMessagePhone}
                         isValid
                         required
                         placeholder={placeholder}
                         customModifyValue={(val: string) => {
                            return modifyPhoneNumber(val).slice(0, 4)
                        }}
                         handleChange={(str: string) => {
                             setVerifyCode(str)
                         }}
                         errorMessage={errorMessage.message}
                         errorStyles={{
                            "textOverflow": "ellipsis",
                            "overflowX": "hidden",
                            "white-space": "nowrap",
                            "width": "204px"
                        }}
                        errorWithTitle={true}

            />
            <Button disabled={isLoading || showTimer || disabled || errorMessagePhone}
                    className={`d-flex align-items-center justify-content-center position-relative ${localStyles.timerButton}`}
                    data-loading={isLoading}
                    onClick={() => {
                        sendCodeBySms()
                    }}
            >
                {
                    !isLoading && !showTimer && <span>{t('send_code')}</span>
                }
                {
                    isLoading && <div className={`position-relative ${localStyles.timerSpinner}`}>
                        <ProgressSpinner strokewidth={4} animationduration={2}/>
                    </div>
                }
                {showTimer && <span>00:{timer < 10 ? `0${timer}` : timer}</span>}
            </Button>
            {isVerify && <img src="/assets/img/success.svg" width={26} height={26} alt=""/>}
        </div>
    );
}

export default PhoneVerify;

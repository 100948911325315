import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import i18n from "i18next";
import {Button} from "react-bootstrap";

import axios from "../../lib/axios";
import {setLoadSpinner} from "../../storage/global";

const TabListLogs = () => {

    const dispatch = useDispatch();

    const [list, setList] = useState([])

    useEffect(() => {

        dispatch(setLoadSpinner(true))

        axios.get('/api/admin/list_logs')
            .then(({data}) => {
                // console.log("🚀 list_logs:", data);
                setList(data)
            })
            .finally(() => {
                dispatch(setLoadSpinner(false))
            })
    }, [])

    return (
        <div className={'container-page'} style={{
            height: 'calc(100% - 58px)',
            overflowY: 'auto'
        }}>
            <div className={'row'}>
                <div className={`v-sheet theme--light p-3 pl-25i`}>
                    {list.map((name: string) => {
                        return <div>
                            {name}<br/>
                        </div>
                    })}
                </div>
            </div>
        </div>
    );
};

export default TabListLogs;

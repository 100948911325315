import React, { useMemo }              from 'react';
import { useDispatch, useSelector }    from "react-redux";
import { useTranslation }              from "react-i18next";
import { Form }                        from "react-bootstrap";

import ModalButtons                    from "../shared/modal/ModalButtons";
import { setLoadSpinner }              from "../../storage/global";
import { hideModalForm, unselectList } from "../../utils/misc";

const DeleteParcelsForm = ({deleteMethod, buttons, text}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const list = useSelector(state => state.global.list)
    // const selected = useSelector(state => state.global.selectedList)
    // const count = selected.length

    const selectedList = useMemo(() => {
        return list.filter(parcel => parcel.check)
    }, [list])

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        dispatch(setLoadSpinner(true))

        deleteMethod({uids: selectedList.map(value => value.uid)})
            .then(() => {
                hideModalForm(dispatch)()
                unselectList(selectedList, true)
            })
            .finally(() => dispatch(setLoadSpinner(false)))
    }

    return (
        <Form noValidate onSubmit={handleSubmit} className={''}>

            <table style={{
                maxWidth: '380px'
            }} className={'w-100'}>
                <tbody>
                <tr>
                    <td valign={'middle'}>
                        <img className={'mr-2'} src="/assets/img/danger.svg" alt="delete"/>
                    </td>
                    <td>
                        <span>{text}</span>
                    </td>
                </tr>
                </tbody>
            </table>

            {
                buttons
                    ? <ModalButtons buttons={buttons}/>
                    :
                    <ModalButtons buttons={{
                        cancel: {
                            text: t('cancel'),
                            variant: 'outline-primary',
                            type: 'button',
                            action: hideModalForm(dispatch)
                        },
                        send: {
                            text: t('delete'),
                            variant: 'primary',
                            type: 'submit'
                        }
                    }}/>
            }
        </Form>
    );
};

export default DeleteParcelsForm;

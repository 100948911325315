import React, {useMemo, useState} from 'react';
import {Form} from "react-bootstrap";
import ModalButtons from "../shared/modal/ModalButtons";
import {useDispatch, useSelector} from "react-redux";
import {hideModalForm, serializeFormToObject} from "../../utils/misc";
import {calculateTotalPay, createPayment} from "../../utils/api";
import {setList, setLoadSpinner} from "../../storage/global";
import {useTranslation} from "react-i18next";

const PayParcelsForm = ({parcels}) => {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const list = useSelector(state => state.global.list);

    function handleSubmit(e) {

        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;
        const requestData = serializeFormToObject(form)

        dispatch(setLoadSpinner(true))

        createPayment({
            parcels
        })
            .then((response) => {
                const div = document.createElement('div');
                document.body.appendChild(div)
                div.innerHTML = response.data;
                div.id = 'alipay';
                document.getElementById('alipaySubmit').setAttribute('target', '_blank')
                div.querySelector('form').submit()
                div.remove();

                hideForm();
                dispatch(setList(list.map(parcel => {
                    parcel.check = false;
                    return parcel;
                })))
            })
            .finally(() => dispatch(setLoadSpinner(false)))

    }

    const [payTotal, setPayTotal] = useState(0)
    useMemo(() => {
        //request
        calculateTotalPay(parcels).then(({data}) => {
            setPayTotal(data.total)
        })
    }, [parcels])

    function hideForm() {
        hideModalForm(dispatch)()
    }

    return (
        <Form noValidate onSubmit={handleSubmit} className={''}>
            <table width={'100%'}>
                <tbody>
                {
                    parcels.map((parcel, key) =>
                        <tr key={key}>
                            <td>
                                {key + 1}.
                            </td>
                            <td className={'d-flex align-items-start'}>{parcel.code}</td>
                            <td className={''} width={''}>
                                {parcel.sum}
                            </td>
                        </tr>
                    )
                }

                <tr>
                    <td><strong>Total Pay:</strong></td>
                    <td></td>
                    <td><strong>{payTotal.toFixed(2)}</strong></td>
                </tr>

                </tbody>
            </table>
            <ModalButtons buttons={{
                cancel: {
                    text: t('cancel'),
                    variant: 'outline-primary',
                    type: 'button',
                    action: hideModalForm(dispatch)
                },
                save: {
                    text: t('pay'),
                    variant: 'primary',
                    type: 'submit'
                }
            }}/>
        </Form>
    );
};

export default PayParcelsForm;


import React                        from 'react';
import { useTranslation }           from "react-i18next";

import ButtonSmallNoBorder          from "../ButtonSmallNoBorder";

const ButtonStyleAction = ({className, buttClassNames, handle, text, iconClasses}) => {
    const {t} = useTranslation();

    function onClick() {
        if (typeof handle === 'function') {
            handle();
        }
    }

    return (
        <div onClick={onClick} className={`${className ? className : ''}`}>
            <ButtonSmallNoBorder classNames={buttClassNames}>
                <i className={`fa ${iconClasses}`}></i>
                <span>{text ? t(text) : t('cancel')}</span>
            </ButtonSmallNoBorder>
        </div>
    );
};

export default ButtonStyleAction;

import React           from 'react';
import { useSelector } from "react-redux";

import Spinner         from "./Spinner";

const SpinnerBackground = () => {

    const loadSpinner = useSelector(state => state.global.spinner)

    return (
        <>
            {
                loadSpinner
                    ? <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                        width: '100%',
                        height: '100%',
                        zIndex: 1
                    }}
                           className={'d-flex justify-content-center align-items-center'}
                    >
                        <Spinner/>
                    </div>
                    : null
            }
        </>
    );
};

export default SpinnerBackground;

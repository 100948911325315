import React, {useState} from 'react';
import PropTypes from 'prop-types'
import {useTranslation} from "react-i18next";
import InputText from "./InputText";
import {EMAIL} from "../../constants";


const InputDigits = ({
                         id,
                         type,
                         placeholder,
                         classes,
                         errorMessage,
                         errorStyles,
                         errorWithTitle,
                         required,
                         value,
                         closeButton,
                         handleChange,
                         handleBlur,
                         validations,
                         styles,
                         isValid,
                         customModifyValue,
                         disabled
                     }) => {

    const {t} = useTranslation();

    const [digit, setDigit] = useState(value);

    function handleValue(value, name, resultValidation) {
        value = modifyValue(value)
        if(customModifyValue) value = customModifyValue(value);
        if (typeof handleChange === 'function')
            handleChange(value, name, resultValidation)
        setDigit(value)
        return 'byValue'
    }

    function modifyValue(value: any): string {
        return value.replaceAll(/[^0-9,\.]|^\.*| +/g, '').replaceAll(/,/g, '.').replace(/\./, ',').replaceAll(/\./g, '').replace(',', '.');
    }

    return (
        <InputText
            styles={{...styles}}
            id={id}
            type={type}
            placeholder={placeholder}
            classes={classes}
            errorMessage={errorMessage}
            required={required}
            value={digit}
            handleChange={handleValue}
            closeButton={closeButton}
            validations={validations}
            handleBlur={handleBlur}
            isValid={isValid}
            disabled={disabled}
            errorStyles={errorStyles}
            errorWithTitle={errorWithTitle}
        />
    );
};

InputDigits.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    classes: PropTypes.string,
    errorMessage: PropTypes.string,
    required: PropTypes.bool
}

export default InputDigits;

import React          from 'react';
import { Fade, Form } from "react-bootstrap";
import PropTypes      from "prop-types";

const InputSelectErrorBlock = ({isError, errorMessage, styles, smapClasses, title}) => {
    return (
        <Fade
            in={isError}
        >
            <Form.Text
                style={{...styles}}
                className={`error-input ${isError ? 'error-input-active' : 'error-input-disable'} ${smapClasses}`}
                title={title}
            >
                {errorMessage}
            </Form.Text>
        </Fade>
    );
};

InputSelectErrorBlock.prototype = {
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
}

export default InputSelectErrorBlock;

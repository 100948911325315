import React, { useEffect, useState }   from 'react';
import { Form }                         from "react-bootstrap";
import { useTranslation }                               from "react-i18next";

import { DEFAULT_ERROR_OBJECT } from "../../constants";
import { validate }             from "../../utils/misc";
import LoadInput                from "../shared/LoadInput";
import InputSelectErrorBlock    from "../shared/InputSelectErrorBlock";

/*
option = [
    {
        text: 'HONGKONG',
        value: 'HKG'
    }
]
 */

const Select = ({
        id,
        className,
        classNameContainer,
        required,
        options,
        selectValue,
        nameValue,
        keyName,
        disabled,
        loaded,
        handleChange,
        errorMessage,
        validations,
        dataTip
    }) => {

    const { t } = useTranslation();

    const [selected, setSelected] = useState(keyName && selectValue ? selectValue[keyName] :  selectValue ?  selectValue : '');
    const [error, setError] = useState({...DEFAULT_ERROR_OBJECT})

    useEffect(() => {
        setSelected(keyName && selectValue ? selectValue[keyName] :  selectValue ?  selectValue : '')
    }, [selectValue])

    const [optionList, setOptionList] = useState([]);


    useEffect(() => {
        if (options && Array.isArray(options) && options.length) {
            const newOptions = {};
            options.forEach(v => {
                if (v) newOptions[typeof v === 'string' ? v : v[keyName]] = v
            })
            // setOptionList(Object.values(newOptions));
            setOptionList(options);
        }
    }, [options])

    function onChange(event) {
        if (typeof handleChange === 'function') {
            const value = options.find(v => typeof v === 'string' ? v === event.currentTarget.value : v[keyName] === event.currentTarget.value);
            handleChange(value, id)
        }
        if (selectValue === undefined)
            setSelected(event.currentTarget.value)

        validate(event.currentTarget.value, validations, setError, DEFAULT_ERROR_OBJECT)
    }

    const emptyListError = (!optionList || !optionList.length) ? t('NoOptionsAvailable') : '';

    return (
        <div className={`position-relative ${classNameContainer}`}>
            <Form.Select
                data-tip={dataTip}
                isInvalid={error.isError || (errorMessage && errorMessage.length) || (required && !selected)}
                onChange={onChange}
                value={selected}
                disabled={disabled || loaded || emptyListError}
                id={id}
                className={`${className ? className : ''} my-select`}>
                {
                    optionList
                        ? optionList.map((option, key) => {
                            if (!option) return null
                            return <option key={key} value={option[keyName]}>{typeof option === 'string' ? option : option[nameValue]}</option>
                        })
                        : null
                }
            </Form.Select>
            <InputSelectErrorBlock
                isError={error.isError || !!(errorMessage && errorMessage.length) || emptyListError}
                errorMessage={errorMessage || emptyListError}
            />
            <LoadInput loaded={loaded}/>
        </div>
    );
};

export default Select;

import React, { memo }       from 'react';
import { useTranslation }    from "react-i18next";

import SearchInput           from "../../components/shared/SearchInput";
import DeleteButton          from "../../components/shared/pages_buttons/DeleteButton";
import PrintStickerButton    from "../../components/shared/pages_buttons/PrintStickerButton";
import SelectAllButton       from "../../components/shared/pages_buttons/SelectAllButton";
import ButtonStyleAction     from "../../components/shared/pages_buttons/ButtonStyleAction";
import EditButton from "../../components/shared/pages_buttons/EditButton";

const ActionBlock = (props) => {

    const {t} = useTranslation();

    return (
    <>
        <div className={'page-actions d-flex-mobile d-flex-mobile-none justify-content-between'}>
            <div className={'d-flex justify-content-start align-items-center'}>
                <SelectAllButton items={props.rootItems} isSelectedAll={props.isSelectedAll} handle={props.handle} className={'mr-3'}/>
                <SearchInput handleSearch={props.handleSearch}/>
            </div>
            <div className={'d-flex justify-content-end align-items-center'}>
                <EditButton handle={props.handleEdit} className={'ml-5'}/>
                <DeleteButton handle={props.handleDelete} className={'ml-5'} deleteFormData={props.deleteFormData}>
                    <span>{t('remove_from_shipment')}</span>
                </DeleteButton>
                <PrintStickerButton handle={props.handlePrint} className={'ml-5'}/>
                <ButtonStyleAction handle={props.openGroupSearch} className={'ml-5'} text={'GroupSearch'} iconClasses={'fa-search mr-1'}/>
            </div>
        </div>
        <div className={'page-actions d-mobile'}>
            <div className={'d-flex justify-content-between align-items-center px-2'}>
                <SelectAllButton items={props.rootItems} isSelectedAll={props.isSelectedAll} handle={props.handle} className={''}/>
                <SearchInput className={'col-6'} handleSearch={props.handleSearch}/>
            </div>
        </div>
    </>
    )
};

export default memo(ActionBlock);

import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {
    setAlertShow,
    setCurrentPage,
    setList,
    setLoadSpinner,
    setModalForm,
    setSearch,
    setSelectedList,
    setTokenInfo,
    setTrackerList,
    setGroupSearchesCheckboxes,
    setListPropertiesSelectFromOut
} from "../../storage/global";

import {
    CHINA_TRACK, CODE,
    COMMODITY, CONSIGNEE,
    DATE,
    DESC,
    QTY, routesNames, SELECTED,
    SEND_BY, SUM_2,
    TO_COUNTRY,
    TRACK_NUMBER, TRACKER,
    SERVICE_TYPE,
    WEIGHT
} from "../../constants";

import ActionBlock from "./ActionBlock";
import moment from "moment/moment";
import CheckBox from "../../components/input/CheckBox";
import PayParcelsForm from "../../components/forms/PayParcelsForm";
import PrintSettingsForm from "../../components/forms/PrintSettingsForm";
import SentParcelDetailForm from "../../components/forms/SentParcelDetailForm";
import PageListTemplate from "../../components/shared/PageListTemplate";
import PageMenu from "../../components/shared/PageMenu";
import ExportButton from "../../components/shared/pages_buttons/ExportButton";
import ButtonStyleAction from "../../components/shared/pages_buttons/ButtonStyleAction";
import CheckboxList from "../../components/shared/CheckboxList";
import PrintStickerButton from "../../components/shared/pages_buttons/PrintStickerButton";

import { exportSentParcels, getSentParcels, getTrackers } from "../../utils/api";
import { goTrackingSystem, viewAlert }                                      from "../../utils/misc";

const SentParcels = () => {

    const order = {
        direction: DESC,
        name: DATE
    };

    const date = moment();

    const filename = `sent_parcels_${date.format('YYYY-MM-DD_HH_mm')}.xlsx`;

    const {t} = useTranslation();

    const dispatch = useDispatch();
    const list = useSelector(state => state.global.list);
    const groupSearchesCheckboxes = useSelector(state => state.global.groupSearchesCheckboxes.sentParcels);
    const withGroupFilter = useSelector(state => state.global.groupSearchesCheckboxes.withGroupFilter);
    const selectFromOut = useSelector(state => state.global.listProperties.selectFromOut);

    const [checkedAll, setCheckedAll] = useState(false);
    const [listAfterFilter, setListAfterFilter] = useState([]);

    // //Clear before destroy component
    useEffect(() => () => {
        dispatch(setModalForm({show: false}))
        dispatch(setAlertShow(false))
        dispatch(setSearch(''))
        dispatch(setList([]))
        dispatch(setSelectedList([]))
        dispatch(setTrackerList([]))
        dispatch(setCurrentPage(routesNames.SENT_PARCELS))
    }, [])

    useEffect(() => {
        if(selectFromOut && selectFromOut.length && list.length && list[0].list_type === 'sent-parcels') {
            dispatch(setList(list.map(parcel => {
                if (parcel.is_paid === "false") parcel.check = true;
                else parcel.check = false;
                return parcel;
            })));
            dispatch(setListPropertiesSelectFromOut([]));
            dispatch(setModalForm({
                title: t('pay').slice(0, 1).toUpperCase() + t('pay').slice(1),
                form: <PayParcelsForm parcels={selectFromOut}/>,
                fullScreen: true
            }))
        }
    }, [list, selectFromOut])

    const groupSearchesCheckboxesCodes = useMemo(() => Object.keys(groupSearchesCheckboxes).filter(code => groupSearchesCheckboxes[code]), [groupSearchesCheckboxes])

    function orderedHook(parcels) {
        if (parcels && parcels.length) {
            const trackersFromLocalStorageJSON = localStorage.getItem('trackers');
            let trackers = {};
            let expire = 0;
            const currentTimestamp = new Date().getTime();
            if (trackersFromLocalStorageJSON) {
                const trackersFromLocalStorage = JSON.parse(trackersFromLocalStorageJSON)
                if (trackersFromLocalStorage) {
                    expire = Number(trackersFromLocalStorage.expire);
                    const isExpire = currentTimestamp > expire
                    if (!isExpire) {
                        trackers = {...trackersFromLocalStorage.list};
                    }
                }
            }

            const loadCodes = [];
            for (let index = 0; index < parcels.length; index++) {
                const parcel = parcels[index];

                if (!trackers[parcel.code] || trackers[parcel.code].eventDescr_descrEN === "No status") {
                    loadCodes.push(parcel.code);
                }
            }
            loadCodes.length && getTrackers(loadCodes, dispatch, t('noStatus'));
        }
    }

    function getList(date) {
        return getSentParcels(date)
    }

    function handleSearch() {
        dispatch(setSelectedList([]))
    }

    function handleExport() {
        const sendList = [...list];
        sendList.forEach( el => {
            let service_type = el.service_type
            if(el.service_type === "0") service_type = 'TO DOOR';
            if(el.service_type === "1") service_type = 'PUDO';
            el.service_type = service_type;
        })
        return exportSentParcels(sendList)
    }

    function openGroupSearch() {
        dispatch(setModalForm({
            title: t('GroupSearchMenu'),
            form: <CheckboxList list={groupSearchesCheckboxes} setList={saveAndApplyGroupSearch}/>
        }))
    }

    function saveAndApplyGroupSearch(list) {
        dispatch(setGroupSearchesCheckboxes({
            page: 'sentParcels',
            val: list
        }))
    }

    function handleDatePicker(dateObject) {

        dispatch(setLoadSpinner(true))

        const date = {
            start: dateObject.firstDate.format('YYYY.MM.DD'),
            end: dateObject.secondDate.format('YYYY.MM.DD'),
        }

        getList(date)
            .then(({data}) => {
                const list_ = data.map(v => {
                    switch (Number(v.sensitive_goods)) {
                        case 0:
                            v.sensitive_goods = t('simple_goods');
                            break;
                        case 1:
                            v.sensitive_goods = t('sensitive_goods');
                            break;
                        case 2:
                            v.sensitive_goods = t('pure_buttery');
                            break;
                        default:
                            break;
                    }

                    v[SUM_2] = Number(
                        v[SUM_2]
                            .toString()
                            .replace(',', '.')
                            .replace(' ', '')
                            .replace(' ', '')
                    )
                        .toFixed(2)

                    return v;
                }).map(parcel => {
                    parcel.list_type = 'sent-parcels';
                    return parcel;
                })
                dispatch(setList(list_, t('noStatus')))
                return {
                    data: list_
                }
            })
            .then(data => {
                orderedHook(data.data)
                return data;
            })
            .catch(({response}) => viewAlert(dispatch, response))
            .finally(() => dispatch(setLoadSpinner(false)))
    }

    function handleDoubleClick(event, parcel, ref) {
        dispatch(setModalForm({
            title: 'Parcel details',
            form: <SentParcelDetailForm data={parcel}/>,
            fullScreen: true,
            hideOut: true,
        }))
    }


    function handleCheck(value, item, ref) {
        dispatch(setList(list.map(parcel => {
            if (item.uid === parcel.uid)
                parcel.check = !parcel.check;
            return parcel;
        })));
    }

    function handleClickParcel(event, item, ref) {
    }

    function handleAllChek(value, id, ref) {
        const newList = [...list];
        (listAfterFilter && listAfterFilter.length ? listAfterFilter : list).forEach(parcel => {
            const numEl = list.findIndex(el => el.uid === parcel.uid)
            if (numEl >= 0) newList[numEl].check = value;
        });
        dispatch(setList(newList));
        setCheckedAll(value)
    }

    function handlePay(listForPay) {
        console.log('handlePay: ', listForPay)
    }

    function handleSelectedToPay(event, modifyList) {
        dispatch(setList(modifyList));
    }

    const listHead = [
        {
            name: SELECTED, col: 1, text: '+', sortArrow: false, getChildren: (v, k) => {
                return <CheckBox
                    id={'all-check'}
                    className={'check-input'}
                    styles={{fontSize: '20px'}}
                    handle={handleAllChek}
                    value={checkedAll}
                />
            }
        },
        {name: SUM_2, col: 2, text: t('freight_cost')},
        {name: CHINA_TRACK, col: 2, text: t('Domestic_express_tracking')},
        {name: DATE, col: 2, text: t('data_sent')},
        {name: TRACK_NUMBER, col: 3, text: t('track_number')},
        {name: COMMODITY, col: 2, text: t('commodity')},
        {name: SERVICE_TYPE, col: 2, text: t('TypeDelivery')},
        {name: WEIGHT, col: 2, text: t('weight')},
        {name: TRACKER, col: 2, text: t('tracking')},
        {name: SEND_BY, col: 2, text: t('ship')},
        {name: TO_COUNTRY, col: 2, text: t('to')},
        {name: CONSIGNEE, col: 2, text: t('consignee')},
    ]

    const listItem = [
        {
            name: SELECTED, col: 1, isTrackNumber: false, getChildren: (parcel) => {
                return <CheckBox
                    className={'check-input'}
                    styles={{fontSize: '20px'}}
                    handle={(value, id, ref) => handleCheck(value, parcel, ref)}
                    value={!!parcel.check}
                />
            }
        },
        {
            name: SUM_2, col: 2, isTrackNumber: false, getChildren: parcel => {
                return <div className={'text-minimise '}>
                    <span style={{color: parcel.is_paid === 'true' ? '#2da771' : '#EC1944'}}>{parcel[SUM_2]}</span>
                </div>
            }
        },
        {name: TRACK_NUMBER, col: 2, isTrackNumber: false},
        {name: DATE, col: 2, isTrackNumber: false},
        {
            name: CODE, col: 3, isTrackNumber: true,
            click: (event, parcel, ref) => {
                goTrackingSystem(parcel.code)
            }, attr: {"no-select": 'no-select'},
            copy: true,
        },
        {name: COMMODITY, col: 2, isTrackNumber: false},
        {
            name: SERVICE_TYPE,
            col: 2,
            isTrackNumber: false,
            getChildren: (parcel) => {
                if(parcel.service_type === "0") return t('toDoor')
                if(parcel.service_type === "1") return t('PUDO')
                return t('error')
            }
        },
        {name: WEIGHT, col: 2, text: t('weight')},
        {
            name: TRACKER, col: 2, isTrackNumber: false, afterLoadData: true, getChildren: parcel => {
                switch (parcel.status) {
                    case '10': {
                        parcel.tracker = t('returned')
                        break;
                    }
                    case '19': {
                        parcel.tracker = t('write_off_parcel')
                        break;
                    }

                    default:
                        break;
                }
                return parcel.tracker;
            }
        },
        {name: SEND_BY, col: 2, isTrackNumber: false},
        {name: TO_COUNTRY, col: 2, isTrackNumber: false},
        {name: CONSIGNEE, col: 2, isTrackNumber: false},
    ]

    const listItemMobile = {
        firstColumn: [
            {
                name: CODE, nameColumn: t('track_number'), col: 24, isTrackNumber: true,
                click: (event, parcel, ref) => {
                    goTrackingSystem(parcel.code)
                }, attr: {"no-select": 'no-select'},
                copy: true,
            },
            {name: COMMODITY, nameColumn: t('commodity'), col: 24, isTrackNumber: false},
            {name: TO_COUNTRY, nameColumn: t('to'), col: 24, isTrackNumber: false},
            {name: TRACKER, nameColumn: t('tracking'), col: 12, isTrackNumber: false, afterLoadData: true},
            {
                name: SERVICE_TYPE,
                nameColumn: t('TypeDelivery'),
                col: 12,
                isTrackNumber: false,
                getChildren: (parcel) => {
                    if(parcel.service_type === "0") return <div className={``}>
                        {t('toDoor')}
                    </div>
                    if(parcel.service_type === "1") return <div className={``}>
                        {t('PUDO')}
                    </div>
                    return <div className={``}>
                    {t('error')}
                </div>
                }
            },
        ],
        secondColumn: [
            {
                name: SELECTED, nameColumn: t(''), col: 24, isTrackNumber: false, classTableItem: 'w-100',
                getChildren: (parcel) => {
                    return <div className={'d-flex justify-content-between'}>
                        <div className={'text-minimise '}>
                            <span style={{
                                color: parcel.is_paid === 'true' ? '#2da771' : '#EC1944',
                                fontSize: '10px'
                            }}>{parcel[SUM_2]}</span>
                        </div>
                        <CheckBox
                            className={'check-input'}
                            styles={{fontSize: '20px'}}
                            handle={(value, id, ref) => handleCheck(value, parcel, ref)}
                            value={!!parcel.check}
                        />
                    </div>
                }
            },
            {name: DATE, nameColumn: t('data_sent'), col: 24, isTrackNumber: false},
            {name: QTY, nameColumn: t('qty_2'), col: 8, isTrackNumber: false},
            {name: WEIGHT, nameColumn: t('weight'), col: 8, isTrackNumber: false},
            {name: SEND_BY, nameColumn: t('ship'), col: 8, isTrackNumber: false},
            {name: CONSIGNEE, nameColumn: t('consignee'), col: 24, isTrackNumber: false},
        ]
    }

    function setDataAfterFilter(data) {
        setListAfterFilter([...data])
    }

    function handlePrint() {
        dispatch(setModalForm({
            title: t('printSettings'),
            form: <PrintSettingsForm/>,
            fullScreen: true,
        }))
    }

    return (
        <div className={'container-page h-100'}>

            <ActionBlock
                handleSearch={handleSearch}
                handleExport={handleExport}
                handlePrint={handlePrint}
                openGroupSearch={openGroupSearch}
                exportFilename={filename}
                handleDatePicker={handleDatePicker}
                handlePay={handlePay}
                handleSelectedToPay={handleSelectedToPay}
                selectedList={list.filter(v => !!v.check && v.is_paid === 'false')}
            />
            <PageListTemplate
                srcOrder={order}
                uniqueRowId={'uid'}
                classesRow={'cursor-pointer'}
                listHead={listHead}
                listItem={listItem}
                listItemMobile={listItemMobile}
                emptyListText={t('noSentParcels')}
                disableSelect
                handleClick={handleClickParcel}
                handleDoubleClick={handleDoubleClick}
                listForFilter={withGroupFilter ? groupSearchesCheckboxesCodes : null}
                orderedHook={data => setDataAfterFilter(data)}
            />
            <PageMenu stylesMenuList={{width: '12rem'}}>
                <ExportButton handle={handleExport} filename={filename} text={t('exportToExcel')}/>
                <PrintStickerButton handle={handlePrint}/>
                <ButtonStyleAction handle={openGroupSearch} text={'GroupSearch'} iconClasses={'fa-search mr-1'}/>
            </PageMenu>
        </div>
    )
}

export default SentParcels;

import React, { useEffect, useState, useMemo }          from 'react';

import StatisticList                                  from "../../../components/shared/RequestSettings/StatisticList";
import StatisticLimits                                  from "../../../components/shared/RequestSettings/StatisticLimits";



const HideTabStatisticSeparated = () => {
    return (
        <>
            <div className="col-6 h-100">
                <StatisticList/>
            </div>
            <div className="col-6 h-100">
                <StatisticLimits/>
            </div>
        </>
    );
};


export default HideTabStatisticSeparated;

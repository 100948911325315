import React, { useEffect, useState } from 'react';
import { useTranslation }             from "react-i18next";
import PropTypes                      from "prop-types";

import InputText           from "./InputText";

const InputIdCardNumber = ({
                               id,
                               type,
                               placeholder,
                               classes,
                               errorMessage,
                               required,
                               value: v,
                               closeButton,
                               handleChange,
                               handleBlur,
                               validations,
                               errorStyles
                           }) => {

    const {t} = useTranslation();

    const [number, setNumber] = useState(v ?? '')

    useEffect(() => {
        setNumber(v ?? '')
    }, [v])

    function onChange(value, name) {
        if (typeof handleChange === 'function') {
            handleChange(value, name)
        }
        if (v === undefined) {
            // setNumber(value.toString().replaceAll(/\D/g, ''))
            setNumber(value)
        }
    }

    return (
        <InputText
            id={id ?? 'ic_crd_num'}
            type={type ?? 'text'}
            placeholder={placeholder ?? t('ic_crd_num')}
            classes={`form-placeholder form-input ${classes ?? ''}`}
            errorMessage={errorMessage}
            required={required}
            value={number}
            handleChange={onChange}
            handleBlur={handleBlur}
            closeButton={closeButton}
            validations={validations}
            errorStyles={errorStyles}
        />
    );
};

InputIdCardNumber.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    classes: PropTypes.string,
    errorMessage: PropTypes.string,
    required: PropTypes.bool
}

export default InputIdCardNumber;

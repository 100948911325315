import React, { useEffect, useState }                           from 'react';
import { useSelector }                                          from "react-redux";
import { useNavigate, Outlet }                                  from "react-router-dom";
import { useTranslation }                                       from "react-i18next";

import { routesNames } from "../../constants";

const AdminPanel = () => {

    const navigate = useNavigate();
    const {t} = useTranslation();
    const profile = useSelector(state => state.global.profile)

    const currentPath = document.location.pathname
    const tabs = [
        {
            name: 'TabSystemSettings',
            path: routesNames.SYSTEM_SETTINGS
        },
        {
            name: 'TabPollSettings',
            path: routesNames.POLL_SETTINGS
        },
        {
            name: 'TabResponsibleEmails',
            path: routesNames.RESPOSIBLE_EMAILS
        },
        {
            name: 'ProfileSettings',
            path: routesNames.PROFILE_SETTINGS
        },
        {
            name: 'NotificationSettings',
            path: routesNames.NOTIFICATION_SETTINGS
        },
        {
            name: 'ListLogs',
            path: routesNames.LIST_LOGS
        },
        {
            name: 'SpeedOfRequest',
            path: routesNames.REQUEST_SETTINGS_STATISTIC
        },
    ];

    const [activeTab, setActiveTab] = useState(tabs.find(tab => currentPath.includes(tab.path)) ?? tabs[0]);

    useEffect(() => {
        if (profile.username !== 'od@rosanexpress.com')
            navigate('/')
    }, [])

    function goTo(nameTab) {
        setActiveTab(nameTab)
        navigate(`/${nameTab.path}`)
    }

    return (
        <div className={'container-fluid h-100 overflow-auto'}>
            <div className='tabs'>
                {tabs.map((nameTab: string) => (
                    <div
                        key={nameTab.name}
                        className={`tab ${activeTab.name === nameTab.name ? 'select' : ''}`}
                        onClick={_ => goTo(nameTab)}
                    >
                        {t(nameTab.name)}
                    </div>
                ))}
            </div>
            <Outlet/>
        </div>
    );
};

export default AdminPanel;

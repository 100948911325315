import React              from 'react';
import { useTranslation } from "react-i18next";

const NothingPage = ({searchLength, searchText, emptyListText}) => {

    const {t} = useTranslation();

    return (
        <div className={'d-flex justify-content-center align-items-center'}>
                                <span style={{
                                    marginTop: '3rem'
                                }}>
                                    {
                                        searchLength
                                            ? searchText ?? t('nothingFound')
                                            : emptyListText
                                    }
                                </span>
        </div>
    );
};

export default NothingPage;



import React, { useMemo, useState, useRef }       from 'react';
import { useDispatch, useSelector }     from "react-redux";
import ReactTooltip                     from "react-tooltip";
import { useTranslation }               from "react-i18next";

import ChargeForm                       from "../forms/ChargeForm";
import { setModalForm, setShowMenu }    from "../../storage/global";
import NotificationDropDown from "./NotificationDropDown";

const NotifBtn = ({isMobile}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const ref = useRef();

    const dataTip = t('Notification');

    const poll = useSelector(state => state.global.poll);
    const balanceNotPaid = useSelector(state => state.global.balanceNotPaid);
    const lostList = useSelector(state => state.global.lost.list)

    const notifsCount = useMemo(() => {
        let count = 0;
        if (Number(balanceNotPaid.debt) > 0) count++;
        if (poll.id) count++;
        if (lostList.length) count = count + lostList.length;
        return count;
    }, [balanceNotPaid, poll, lostList]);

    const [showNotificationDropDown, setShowNotificationDropDown] = useState(false)

    function openForm() {
        setShowNotificationDropDown(!showNotificationDropDown);
    }

    return (
        <div className={`d-flex p-relative ${isMobile ? '' : 'ml-2'}`}>
            <div
                ref={ref}
                data-tip={dataTip}
                onClick={() => openForm()}
                className={`p-relative cursor-pointer d-flex me-2 notifsBell`}
            >
                <img src="/assets/img/bell.svg" alt="notif"/>
                {!notifsCount ? null : <div className={`notifsBellCount`}>
                    {notifsCount}
                </div>}
                {isMobile ? <span className={`ml-1`}>{dataTip}</span> : ''}
            </div>
            <NotificationDropDown show={showNotificationDropDown} close={() => setShowNotificationDropDown(!showNotificationDropDown)} parentElementRef={ref}/>
        </div>
    );
};

export default NotifBtn;

import React, { useMemo }           from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation }           from "react-i18next";
import { Button }                   from "react-bootstrap";

import { setModalForm }             from "../../storage/global";
import WarehouseForm                from "../../components/forms/WarehouseForm";

const SendToWarehouseButton = ({className, isSelectedAll}) => {

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const list = useSelector(state => state.global.list)
    // const selected = useSelector(state => state.global.selectedList)
    // const count = selected.length

    const count = useMemo(() => {
        return list.filter(parcel => parcel.check).length
    }, [list])

    function send() {
        dispatch(setModalForm({
            title: t('send_to_wh'),
            form: <WarehouseForm count={count}/>,
            fullScreen: true,
        }))
    }

    return (
        <div className={`${className ? className : ''}`}>
            <Button disabled={!count} onClick={send}>
                <span style={{
                    display: 'inline-block',
                    minWidth: '30px'
                }}>{count}</span> {t('send_to_wh')}
            </Button>
        </div>
    );
};

export default SendToWarehouseButton;

import React, { useState, useMemo }          from 'react';
import { Button }                            from "react-bootstrap";

import HideTabStatisticSolid         from "./HideTabStatisticSolid";
import HideTabStatisticSeparated     from "./HideTabStatisticSeparated";



const TabRequestSettingsStatistic = () => {

    const [solidView, setSolidView] = useState(false);

    function changeView() {
        setSolidView(!solidView);
    }

    return (
        <div className={'container-page height-sub-tabs row'}>
            {solidView ? <HideTabStatisticSolid/>
                : <HideTabStatisticSeparated/>}
            <button
                className={`btn p-0`}
                style={{
                    display: 'block',
                    height: '15px',
                    width: '25px',
                    padding: '0px !important',
                    position: 'absolute',
                    bottom: 0
                }}
                onClick={changeView}
            >{}</button>
        </div>
    );
};


export default TabRequestSettingsStatistic;

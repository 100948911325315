import React              from 'react';
import ReactDOM           from 'react-dom';
import { BrowserRouter }  from 'react-router-dom'
import Loader             from "./components/Loader";
import { Provider }       from "react-redux";
import { store }          from './storage'
import Main               from "./Main";
import SpinnerBackground  from "./components/shared/SpinnerBackground";
import MyAlert            from "./components/shared/modal/MyAlert";
import MyModalForm        from "./components/shared/modal/MyModalForm";
import { ToastContainer } from "react-toastify";

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <Main/>
        </BrowserRouter>
        <Loader/>
        <SpinnerBackground/>
        <MyAlert/>
        <MyModalForm/>
        <ToastContainer/>
    </Provider>,
    document.getElementById('root')
);

import React, { useEffect, useState }          from 'react';
import { Button }                                       from "react-bootstrap";
import { t } from 'i18next';

import InputText                                        from "../../input/InputText";
import CheckBox                                         from "../../input/CheckBox";
import PollQuestionEditing                              from "./PollQuestionEditing";
import ButtonAdmin                                      from "../pages_buttons/ButtonAdmin";
import MyDatePicker                                     from "../datepicker/MyDatePicker";


const PollEditing = ({
    poll,
    pollsNames,
    changePoll,
    savePoll,
    resetPoll
}) => {

    const defQuestion = {
        active: false,
        require: false,
        question: {
            en: '',
            cn: ''
        },
        type: 0,
        variants: [] // defSelVariant
    }
    const defSelVariant = {
        en: '',
        cn: ''
    }

    const [locPoll, setLocPoll] = useState(poll);
    const [nameErrorText, setNameErrorText] = useState(!poll.name ? t('validEmpty') : pollsNames.find( p => p.name === poll.name && p.id !== poll.id) ? t('validCopy') : '');
    const [errorQuestion, setErrorQuestion] = useState(poll.id ? false : true);

    useEffect(() => {
        setLocPoll(poll)
    }, [poll])

    useEffect(() => {
        setNameErrorText(!locPoll.name ? t('validEmpty') : pollsNames.find( p => p.name === poll.name && p.id !== poll.id) ? t('validCopy') : '')
    }, [locPoll, pollsNames])

    const createNewQuestion = () => {
        const _poll = {...locPoll};
        _poll.questions.push(defQuestion);
        setErrorQuestion(true)
        changePoll(_poll);
    };
    const deleteQuestion = (index) => {
        const _poll = {...locPoll};
        _poll.questions.splice(index, 1);
        const errInOtherQue = _poll.questions.find( (que, i) => {
            return !que.question.en || !que.question.cn || que.variants.find((v) => (!v.en || !v.cn))
        });

        setErrorQuestion(errInOtherQue)
        changePoll(_poll);
    };
    const changeQuestion = (index, newQue) => {
        const _poll = {...locPoll};
        _poll.questions[index] = newQue;
        if (_poll.questions[index].type === 1 &&
            _poll.questions[index].variants.length === 0) _poll.questions[index].variants = [defSelVariant]
        if (_poll.questions[index].type === 0 &&
            _poll.questions[index].variants.length !== 0) _poll.questions[index].variants = []

        const errQue = !newQue.question.en || !newQue.question.cn || !!(newQue.variants.find( v => !v.en || !v.cn));
        const errInOtherQue = _poll.questions.find( (que, i) => {
            if (index === i) return false;
            return !que.question.en || !que.question.cn || !!que.variants.find((v) => !v.en || !v.cn)
        });

        setErrorQuestion(errQue || errInOtherQue)
        changePoll(_poll);
    };

    function activePollState(newValue) {
        const _poll = {...locPoll};
        _poll.active = newValue;
        changePoll(_poll);
    }
    function requirePollState(newValue) {
        const _poll = {...locPoll};
        _poll.require = newValue;
        changePoll(_poll);
    }
    function changeName(newValue) {
        const _poll = {...locPoll};
        _poll.name = newValue;
        changePoll(_poll);
    }
    function changePeriod(dateObject) {
        const _poll = {...locPoll};
        _poll.date_from = new Date(dateObject.firstDate.format('YYYY.MM.DD')).getTime();
        _poll.date_to = new Date(dateObject.secondDate.format('YYYY.MM.DD')).getTime();
        changePoll(_poll)
    }

    return (
        <div className='v-card v-sheet theme--light p-3 my-3 poll'>
            <div className="row">
                <div className="col">
                    <div className="my-3">
                        {!locPoll.id && <CheckBox
                            className={'check-input m-1 mli-4'}
                            styles={{fontSize: '20px'}}
                            handle={(value, id) => activePollState(value)}
                            id={'poll.active'}
                            value={locPoll.active}
                            label={"Опитування активне?"}
                        />}
                        <CheckBox
                            className={'check-input m-1 mli-4'}
                            styles={{fontSize: '20px'}}
                            handle={(value, id) => requirePollState(value)}
                            id={'poll.require'}
                            value={locPoll.require}
                            label={"Опитування обов'язкове?"}
                        />
                    </div>
                    <div className="d-flex row px-0 mt-2 mx-0 justify-content-around">
                        <MyDatePicker
                            handle={changePeriod}
                            startEnd
                            firstDate={new Date(locPoll.date_from ?? Date.now())}
                            secondDate={new Date(locPoll.date_to ?? Date.now()+1000*60*60*24)}
                            className={'d-flex col px-0 hmc'}
                            stylesFirstDate={{marginRight: '15px'}}
                            classNameFirstDate={'mr-3'}
                            textFirst={"З дати"}
                            textSecond={"До дати"}
                            colFirst={['auto', 'auto']}
                            colSecond={['auto', 'auto']}
                            TitleCClassNames={"pl-15 "}
                            TitleClassNames={"lh-0"}
                            DPCClassNames={"mtb-9 minw-100"}
                        />
                        <InputText
                            handleChange={ (val) => changeName(val)}
                            value={locPoll.name}
                            id={'poll.name'}
                            groupClssses={'my-2 mw-500'}
                            placeholder={'Назва опитувальника (унікальна)'}
                            errorMessage={nameErrorText}
                            closeButton
                            classNamesClear={'r-23'}
                        />
                    </div>
                </div>
            </div>
            <div className='mx-5 questions'>
                <hr/>
                {!locPoll.questions || !locPoll.questions.length ? null :
                    locPoll.questions.map( (que, queIndex) =>
                        <PollQuestionEditing que={que} key={queIndex} delQue={ _ => deleteQuestion(queIndex)} changeQue={ newQue => changeQuestion(queIndex, newQue) }/> )
                }
                <div className="newQuestion">
                    <ButtonAdmin onClick={createNewQuestion} text={"Додати запитання"} buttonClasses={"my-2 col-bg-add"} iconClasses={"mr-1 fa-plus"}/> 
                </div>
            </div>
            <div className={'row mt-3'}>
                <div className={'col-6'}>
                </div>
                <div className={'col-3'}>
                    <Button onClick={resetPoll} className={'w-100'} variant={'outline-primary'}>Скинути</Button>
                </div>
                <div className={'col-3'}>
                    <Button disabled={!savePoll || !!nameErrorText || errorQuestion} onClick={savePoll} className={'w-100'} variant={'primary'}>Зберегти</Button>
                </div>
            </div>
        </div>
    );
};

export default PollEditing;


import React, { useEffect, useState, useMemo }          from 'react';
import { useDispatch }                                  from "react-redux";
import { Button }                                       from "react-bootstrap";
import { useTranslation }                               from "react-i18next";
import moment                                           from "moment/moment";

import axios                                            from "../../../lib/axios";
import { setLoad, setLoadSpinner }                      from "../../../storage/global";
import {
    getQueryStatisticsList,
    getRequestsUris,
    getClientIds,
    getReqStatisticExel
}                                                       from "../../../utils/api";
import { viewAlert }                                    from "../../../utils/misc";
import RequestList                                      from "../../../components/shared/RequestSettings/RequestList";
import Select                                           from "../../../components/input/Select";
import MyDatePicker                                     from "../../../components/shared/datepicker/MyDatePicker";


const TabRequestSettingsList = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const defStorage = {
        items: [],
    };

    const nothingSelected = t('nothingSelected');
    const [clientIds, setClientIds] = useState([nothingSelected]);
    const [clientIdForGetResult, setClientIdForGetResult] = useState('');
    const [urls, setUrls] = useState([nothingSelected]);
    const [urlForGetResult, setUrlForGetResult] = useState('');
    const [dateForGetResult, setDateForGetResult] = useState({ date_from: Date.now() - 1000*60*60, date_to: Date.now()});

    const [storage, setStorage] = useState(defStorage);

    const {items, filter} = storage;

    useEffect(() => {
        dispatch(setLoad(false))
        getQueryStatistics({client_id: false})
        getRequestsUriList()
    }, [])

    function getQueryStatistics(params) {
        dispatch(setLoadSpinner(true))
        getQueryStatisticsList(params).then(response => {
                (response?.data?.queryList?.length || (response?.data?.queryList && params)) && setStorage({
                    ...storage,
                    items: response?.data?.queryList
                });
            })
            .catch(({response}) => {viewAlert(dispatch, response)})
            .finally(() => dispatch(setLoadSpinner(false)))
    }

    function getRequestsUriList() {
        dispatch(setLoadSpinner(true))
        getRequestsUris().then(response => {
                setUrls([nothingSelected, ...Object.values(response.data.uris).map(row=>row.req_uri)]);
                return getClientIds().then(response_ids => {
                    setClientIds([nothingSelected, ...Object.values(response_ids.data.client_ids).map(row=>row.client_id)]);
                })
            })
            .catch(({response}) => {viewAlert(dispatch, response)})
            .finally(() => dispatch(setLoadSpinner(false)))
    }


    function loadExcel() {
        getReqStatisticExel(dateForGetResult.date_from === dateForGetResult.date_to ?
            {
                client_id: false,
                query_uri: +urlForGetResult ? urls[urlForGetResult] : null
            }
            : {
                from_time: toTimestamp(dateForGetResult.date_from),
                to_time: toTimestamp(dateForGetResult.date_to),
                client_id: false,
                query_uri: +urlForGetResult ? urls[urlForGetResult] : null
            }
        ).then(({data}) => {
            const date = moment();
            const filename = `requests_list_${date.format('YYYY-MM-DD_HH_mm')}.xlsx`;
            const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename ? filename : 'file.xls'); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch(({response}) => {
            viewAlert(dispatch, response)
        })
        .finally(() => dispatch(setLoadSpinner(false)))
    }

    function handleDatePicker(dateObject) {
        const date = {
            date_from: dateObject.firstDate.format('YYYY.MM.DD HH:mm'),
            date_to: dateObject.secondDate.format('YYYY.MM.DD HH:mm')
        }
        setDateForGetResult(date);

    }

    function loadData() {
        getQueryStatistics(dateForGetResult.date_from === dateForGetResult.date_to ?
            {
                client_id: +clientIdForGetResult ? clientIds[clientIdForGetResult] : false,
                query_uri: +urlForGetResult ? urls[urlForGetResult] : null
            }
            : {
                from_time: toTimestamp(dateForGetResult.date_from),
                to_time: toTimestamp(dateForGetResult.date_to),
                client_id: +clientIdForGetResult ? clientIds[clientIdForGetResult] : false,
                query_uri: +urlForGetResult ? urls[urlForGetResult] : null
            }
        )
    }
    function toTimestamp(strDate){
        var datum = Date.parse(strDate);
        return datum/1000;
    }

    return (
        <div className={'container-page height-sub-tabs'}>
            <div className={'v-card v-sheet theme--light p-3 d-flex align-items-end flex-column'}>
                <div className="row w-100 m-0 pt-10">
                    <MyDatePicker
                        handle={handleDatePicker}
                        startEnd
                        formatProp={'DD/MM/YYYY HH:mm:ss'}
                        showTimeSelect
                        timeFormat="HH:mm"
                        firstDate={new Date(dateForGetResult.date_from ?? Date.now()-1000*60*60)}
                        secondDate={new Date(dateForGetResult.date_to ?? Date.now())}
                        className={'d-flex my-col-10 px-0'}
                        stylesFirstDate={{marginRight: '15px'}}
                        classNameFirstDate={'mr-3'}
                        textFirst={"З дати"}
                        textSecond={"До дати"}
                        colFirst={['auto', 'auto']}
                        colSecond={['auto', 'auto']}
                        TitleCClassNames={"pl-15 "}
                        TitleClassNames={"lh-0"}
                        DPCClassNames={"mtb-9 minw-100"}
                    />
                    <Select
                        id={'Select_ClientId_List'}
                        classNameContainer={"my-col-4 align-self-center"}
                        options={clientIds.map( (client_id, i) => ({value: i.toString(), name: client_id}))}
                        selectValue={clientIdForGetResult && clientIds[clientIdForGetResult] ? {value: clientIdForGetResult, name: clientIds[clientIdForGetResult]} : {}}
                        nameValue={'name'}
                        keyName={'value'}
                        handleChange={ (el, id) => {
                            setClientIdForGetResult(el.value)
                        }}
                        errorMessage={''}
                        validations={{}}
                        loaded={false}
                    />
                    <Select
                        id={'Select_Urls_List'}
                        classNameContainer={"my-col-4 align-self-center"}
                        options={urls.map( (req_uri, i) => ({value: i.toString(), name: req_uri}))}
                        selectValue={urlForGetResult && urls[urlForGetResult] ? {value: urlForGetResult, name: urls[urlForGetResult]} : {}}
                        nameValue={'name'}
                        keyName={'value'}
                        handleChange={ (el, id) => {
                            setUrlForGetResult(el.value)
                        }}
                        errorMessage={''}
                        validations={{}}
                        loaded={false}
                    />
                    <div className="my-col-3 align-self-center">
                        <Button onClick={loadData} className={'hmc mr-3 w-100'}>Показати</Button>
                    </div>
                    <div className="my-col-3 align-self-center">
                        <Button onClick={loadExcel} className={'hmc w-100'}>Скачати</Button>
                    </div>
                </div>
            </div>
            <div className='height-list-filter-list'>
                <div className='list-of-poll mb-4 d-flex flex-column h-100'>
                    <RequestList
                        uniqueRowId={'uid'}
                        emptyListText={'Пусто'}
                        disableSelect
                        items={items}
                    />
                </div>
            </div>
        </div>
    );
};


export default TabRequestSettingsList;

import React, { useState } from 'react';

import CloseInputButton    from "./CloseInputButton";

const Image = ({className, src, alt}) => {

    const [show, setShow] = useState(false);

    function open(event) {
        setShow(!show);
    }

    function close(event) {
        setShow(!show);
    }

    return (
        <div className={`cursor-pointer ${className ? className : ''}`}>
            {
                show
                    ?
                    <div style={{
                        width: '100vw',
                        height: '100vh',
                        position: 'fixed',
                        left: '0',
                        top: '0',
                        backgroundColor: 'rgba(114, 111, 111, 0.47)',
                        padding: '20px',
                    }}
                         className={'d-flex align-items-center'}
                    >
                        <CloseInputButton closeButton clear={close}/>
                        <div className={'w-100'}>
                            <img onClick={open} className={'w-100'} src={src} alt={alt}/>
                        </div>
                    </div>
                    : null

            }
            <img onClick={open} className={'w-100'} src={src} alt={alt}/>
        </div>
    );
};

export default Image;

import React, { useEffect, useState, useMemo }          from 'react';
import { useDispatch }                                  from "react-redux";
import { Button }                                       from "react-bootstrap";
import { useTranslation }                               from "react-i18next";

import {
    REQ_URI,
    AVERAGE_REQUEST_TIME,
    WARNING_NOTIF_MAX_VALUE,
}                                                       from "../../../constants";
import axios                                            from "../../../lib/axios";
import { setLoad, setLoadSpinner }                      from "../../../storage/global";
import {
    getRequestsUris,
    getQueryStatisticsListAvg,
    saveWarningNotificationLimits,
    getWarningNotificationLimits,
}                                                       from "../../../utils/api";
import { viewAlert }                                    from "../../../utils/misc";
import PollEditing                                      from "../../../components/shared/Polls/PollEditing";
import PagePollListTemplate                             from "../../../components/shared/Polls/PagePollListTemplate";
import Select                                           from "../../../components/input/Select";
import MyDatePicker                                     from "../../../components/shared/datepicker/MyDatePicker";
import ButtonAdmin                                      from "../../../components/shared/pages_buttons/ButtonAdmin";
import RequestStatistic                                 from "../../../components/shared/RequestSettings/RequestStatistic";
import InputText                                        from "../../../components/input/InputText";



const HideTabStatisticSolid = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const defStorage = {
        items: [],
        defUrlLimits: [],
    };

    const nothingSelected = t('nothingSelected');
    const [urls, setUrls] = useState([nothingSelected]);
    const [urlForGetResult, setUrlForGetResult] = useState('');
    const [dateForGetResult, setDateForGetResult] = useState({ date_from: Date.now() - 1000*60*60, date_to: Date.now()});

    const [storage, setStorage] = useState(defStorage);

    const {items, defUrlLimits} = storage;

    useEffect(() => {
        dispatch(setLoad(false))
        getQueryStatisticsAvg()
        getRequestsUriList()
    }, [])

    function getQueryStatisticsAvg(params) {
        dispatch(setLoadSpinner(true))
        getQueryStatisticsListAvg(params).then(responseListAvg => {
                return getWarningNotificationLimits().then(responseLimits => {

                    let urlLimits = defUrlLimits;
                    if(responseLimits?.data?.urls?.length) {
                        urlLimits = responseLimits?.data?.urls;
                    }
                    let itemsWithLimits = items;
                    if(responseListAvg?.data?.queryStatistics?.length || (responseListAvg?.data?.queryStatistics && params)) {
                        itemsWithLimits = responseListAvg?.data?.queryStatistics.map((url)=>{
                            const existUrl = urlLimits.length && urlLimits.find((urlSett)=> urlSett.req_uri===url.req_uri);
                            const newMax = existUrl ?
                                urlLimits.find((urlSett)=> urlSett.req_uri===url.req_uri).warningNotifMaxValue
                                : ''
                            return {
                                ...url,
                                [WARNING_NOTIF_MAX_VALUE]: newMax
                            }
                        })
                    }
                    setStorage({
                        items: itemsWithLimits,
                        defUrlLimits: urlLimits
                    });
                })
            })
            .catch(({response}) => {viewAlert(dispatch, response)})
            .finally(() => dispatch(setLoadSpinner(false)))
    }

    function getRequestsUriList() {
        dispatch(setLoadSpinner(true))
        getRequestsUris().then(response => {
                setUrls([nothingSelected, ...Object.values(response.data.uris).map(row=>row.req_uri)]);
            })
            .catch(({response}) => {viewAlert(dispatch, response)})
            .finally(() => dispatch(setLoadSpinner(false)))
    }

    function handleDatePicker(dateObject) {
        const date = {
            date_from: dateObject.firstDate.format('YYYY.MM.DD'),
            date_to: dateObject.secondDate.format('YYYY.MM.DD')
        }
        setDateForGetResult(date)
    }

    function loadData() {
        getQueryStatisticsAvg(dateForGetResult.date_from === dateForGetResult.date_to ? {query_uri: +urlForGetResult ? urls[urlForGetResult] : null} : {
            from_time: toTimestamp(dateForGetResult.date_from),
            to_time: toTimestamp(dateForGetResult.date_to),
            query_uri: +urlForGetResult ? urls[urlForGetResult] : null
        })
    }
    function toTimestamp(strDate){
        var datum = Date.parse(strDate);
        return datum/1000;
    }

    function changeLimit(val, i) {
        const withNewLimits = [...items];
        withNewLimits[i][WARNING_NOTIF_MAX_VALUE] = val;
        setStorage({
            ...storage,
            items: withNewLimits
        });
    }

    function resetReqLimits() {
        let itemsWithLimits = items;
        if(items.length) {
            itemsWithLimits = items.map((url)=>{
                const existUrl = defUrlLimits.length && defUrlLimits.find((urlSett)=> urlSett.req_uri===url.req_uri);
                const newMax = existUrl ?
                defUrlLimits.find((urlSett)=> urlSett.req_uri===url.req_uri).warningNotifMaxValue
                    : ''
                return {
                    ...url,
                    [WARNING_NOTIF_MAX_VALUE]: newMax
                }
            })
        }
        setStorage({
            ...storage,
            items: itemsWithLimits,
        });
    }

    async function saveReqLimits() {
        dispatch(setLoadSpinner(true))
        try {
            const warningNotificationUrls = items
                .filter(url_obj=>url_obj[WARNING_NOTIF_MAX_VALUE] > 0)
                .map(url_obj=>({req_uri: url_obj.req_uri, warningNotifMaxValue: url_obj.warningNotifMaxValue}))
            const responseDangerWorlds = await saveWarningNotificationLimits({warningNotificationUrls});
            viewAlert(dispatch, {responseDangerWorlds})
        } catch(e) {
            viewAlert(dispatch, {"err": e})
        }
        dispatch(setLoadSpinner(false))
    }

    const listHead = [
        {name: REQ_URI, col: 11, text: t('list_head_req_uri'), sortArrow: false, classNames: 'wfc minw-100'},
        {name: AVERAGE_REQUEST_TIME, col: 3, text: t('average_request_time'), sortArrow: false, classNames: 'wfc minw-100'},
        {name: WARNING_NOTIF_MAX_VALUE, col: '', text: t('warning_notification_max_value'), sortArrow: false, classNames: 'wfc minw-100'},
    ]
    const listItem = [
        {name: REQ_URI, col: 12, classNames: 'wfc minw-100 py-1'},
        {name: AVERAGE_REQUEST_TIME, col: 2, classNames: 'wfc minw-100 py-1'},
        {name: WARNING_NOTIF_MAX_VALUE, col: '', classNames: 'wfc minw-100 px-0 py-1', getChildren: (index, key) => {
            return <InputText handleChange={(val)=>changeLimit(val, index)} type='number' value={items[index][WARNING_NOTIF_MAX_VALUE]} id={WARNING_NOTIF_MAX_VALUE}
                placeholder={'ліміт'} closeButton/>
        }},
    ]

    return (
        <div className="h-100">
            <div className={'v-card v-sheet theme--light p-3 d-flex flex-column '}>
                <div className="row w-100 m-0 pt-10">
                    <MyDatePicker
                        handle={handleDatePicker}
                        startEnd
                        firstDate={new Date(dateForGetResult.date_from ?? Date.now()-1000*60*60)}
                        secondDate={new Date(dateForGetResult.date_to ?? Date.now())}
                        className={'d-flex col-6 px-0'}
                        stylesFirstDate={{marginRight: '15px'}}
                        classNameFirstDate={'mr-3'}
                        textFirst={"З дати"}
                        textSecond={"До дати"}
                        colFirst={['auto', 'auto']}
                        colSecond={['auto', 'auto']}
                        TitleCClassNames={"pl-15 "}
                        TitleClassNames={"lh-0"}
                        DPCClassNames={"mtb-9 minw-100"}
                    />
                    <Select
                        id={'Select_Requests_Uris_Solid'}
                        classNameContainer={"col-3 align-self-center pl-0"}
                        options={urls.map( (req_uri, i) => ({value: i.toString(), name: req_uri}))}
                        selectValue={urlForGetResult && urls[urlForGetResult] ? {value: urlForGetResult, name: urls[urlForGetResult]} : {}}
                        nameValue={'name'}
                        keyName={'value'}
                        handleChange={ (el, id) => {
                            setUrlForGetResult(el.value)
                        }}
                        errorMessage={''}
                        validations={{}}
                        loaded={false}
                    />
                    <div className="col-3 align-self-center">
                        <Button onClick={loadData} className={'hmc mr-1 w-100'}>Показати</Button>
                    </div>
                    {/* <div className="col-4 align-self-center">
                        <Button onClick={loadExcel} className={'hmc w-100'}>Скачати</Button>
                    </div> */}
                </div>
            </div>
            <div className='height-list-filter'>
                <div className='list-of-poll mb-2 d-flex flex-column h-100'>
                    <RequestStatistic
                        uniqueRowId={'uid'}
                        emptyListText={'Пусто'}
                        disableSelect
                        items={items}
                        changeLimit={changeLimit}
                        listHead={listHead}
                        listItem={listItem}
                    />
                </div>
            </div>
            <div className={'row mt-3'}>
                <div className={'col-6'}>
                    <Button onClick={resetReqLimits} className={'w-100'} variant={'outline-primary'}>Скинути</Button>
                </div>
                <div className={'col-6'}>
                    <Button onClick={saveReqLimits} className={'w-100'} variant={'primary'}>Зберегти</Button>
                </div>
            </div>
        </div>
    );
};


export default HideTabStatisticSolid;

import React, {useEffect, useState, useMemo, useRef} from 'react';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Button, Form} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import localStyles from './RegisterForm.module.scss'

import {
    CAPTCHA_SWITCH,
    DEFAULT_ERROR_OBJECT,
    EMAIL,
    IC_CRD_NUM, INPUT_CAPTCHA, MANAGER,
    COMPANY_NAME, WECHAT_ID,
    NAME,
    PASSWORD, PASSWORD_AGAIN,
    PASSWORD_CONFIRM,
    DOCUMENTS_IMAGES,
    PHONE,
    routesNames, PHONE_VERIFY
} from "../../constants";
import {
    emailValidate,
    noEmptyValidate, matchValidate,
    numberValidate,
    letterNumberValidate,
    withLastLetter,
    letterSpaceHyphenValidate3,
    letterNumberSpaceHyphenValidate,
    noEmptyArrayValidate,
    passwordValidate,
    validateLength, customValidate
} from "../../utils/validate";
import {checkValidate, serializeFormToObject, viewAlert, debounce} from "../../utils/misc";
import {getManagerList, signup, createUserDocsKK, checkPhoneNumExistKK} from "../../utils/api";
import InputText from "../input/InputText";
import InputName from "../input/InputName";
import InputPhone from "../input/InputPhone";
import InputIdCardNumber from "../input/InputIDCardNumber";
import InputEmail from "../input/InputEmail";
import InputPassword from "../input/InputPassword";
import InputFile from "../input/InputFile";
import Select from "../input/Select";
import Switch from "../input/Switch";
import {setLoadSpinner, setAlertShow} from "../../storage/global";
import Loader from "../Loader";
import Spinner from "../shared/Spinner";
import PhoneVerify from "./PhoneVerify";

const RegisterForm = () => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const defManager = {GUID: '', FullName: t('selectManager')}
    const size_5_MB = t('The_file_size_cannot_exceed_5_MB')

    const [errorCompany, setErrorCompany] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorName, setErrorName] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorPhone, setErrorPhone] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorCardNumber, setErrorCardNumber] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorWechatID, setErrorWechatID] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorEmail, setErrorEmail] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorPassword, setErrorPassword] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorPasswordAgain, setErrorPasswordAgain] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorFiles, setErrorFiles] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorCaptcha, setErrorCaptcha] = useState({...DEFAULT_ERROR_OBJECT});
    const [errorManager, setErrorManager] = useState({...DEFAULT_ERROR_OBJECT});

    const [pass, setPass] = useState({
        first: '',
        second: '',
    })

    const [documentsImages, setDocumentsImages] = useState([])
    const [manager, setManager] = useState(defManager)
    const [managerList, setManagerList] = useState([defManager])
    const [loadManagerList, setLoadManagerList] = useState(true)
    const [robot, setRobot] = useState(false)
    const [trySubmit, setTrySubmit] = useState(false)
    const [phone, setPhone] = useState('')
    const [phoneIsCheckNumExistKK, setPhoneIsCheckNumExistKK] = useState(false)
    const [phoneIsVerify, setPhoneIsVerify] = useState(false)
    const [connectionId, setConnectionId] = useState('')
    const [verifyCode, setVerifyCode] = useState('')

    const errorFilesRef = useRef(errorFiles);
    errorFilesRef.current = errorFiles;

    useEffect(() => {
        getManagerList()
            .then((response) => {
                setManagerList([defManager].concat(response.data))
                setLoadManagerList(false)
            })
            .catch(({response}) => {
                viewAlert(dispatch, response)
            })
    }, [])

    const checkPhoneExistKK = useMemo(() => {
        const [debouncedFunc] = debounce(checkPhoneNumExistKK, 700);
        return debouncedFunc;
    }, [])

    const validations = {}
    validations[COMPANY_NAME] = [noEmptyValidate(t('validEmpty')),
        validateLength(2, 0, t('validLength_2').replace(/(\$\{1\$\})/, 2)),
        letterNumberSpaceHyphenValidate(t('IncorrectYourName'))
    ]
    validations[NAME] = [noEmptyValidate(t('validEmpty')),
        validateLength(2, 0, t('validLength_2').replace(/(\$\{1\$\})/, 2)),
        letterSpaceHyphenValidate3(t('IncorrectYourName'))
    ]
    validations[PHONE] = [noEmptyValidate(t('validEmpty')), numberValidate(t('validNumber')), validateLength(5, 0, t('IncorrectPhoneNumber'))]
    validations[IC_CRD_NUM] = [noEmptyValidate(t('validEmpty')), letterNumberValidate(t('validOnlyDigitsLetter')), validateLength(18, 18, t('IncorrectIDCardNumber')), withLastLetter(17, t('IncorrectIDCardNumber'))]
    validations[WECHAT_ID] = [noEmptyValidate(t('IncorrectWeChatId'))]
    validations[EMAIL] = [noEmptyValidate(t('validEmpty')), emailValidate(t('validEmail'))]
    validations[PASSWORD] = [noEmptyValidate(t('validEmpty')), passwordValidate(t('validPassword'))]
    validations[PASSWORD_CONFIRM] = [noEmptyValidate(t('validEmpty')), passwordValidate(t('validPassword'))]
    validations[DOCUMENTS_IMAGES] = [noEmptyArrayValidate(t('validEmpty'))]
    validations[INPUT_CAPTCHA] = [noEmptyValidate(t('validEmpty'))]
    validations[MANAGER] = [noEmptyValidate(t('validEmpty'))]

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        setTrySubmit(true);

        const form = e.currentTarget;
        const data = serializeFormToObject(form)

        data[PHONE] = data[PHONE].toString().replaceAll(/[ -]*/ig, '')
        data.connection_id = connectionId;
        data.code = verifyCode;

        const errors = [
            checkValidate(data[COMPANY_NAME], validations[COMPANY_NAME], setErrorCompany),
            checkValidate(data[NAME], validations[NAME], setErrorName),
            checkValidate(data[PHONE], validations[PHONE], setErrorPhone),
            checkValidate(data[IC_CRD_NUM], validations[IC_CRD_NUM], setErrorCardNumber),
            checkValidate(data[WECHAT_ID], validations[WECHAT_ID], setErrorWechatID),
            checkValidate(data[EMAIL], validations[EMAIL], setErrorEmail),
            checkValidate(data[PASSWORD], validations[PASSWORD], setErrorPassword),
            checkValidate(data[PASSWORD_CONFIRM], validations[PASSWORD_CONFIRM].concat([matchValidate(data[PASSWORD], t('passwordMatch'))]), setErrorPasswordAgain),
            checkValidate(data[INPUT_CAPTCHA], validations[INPUT_CAPTCHA], setErrorCaptcha),
            checkValidate(data[MANAGER], validations[MANAGER], setErrorManager),
            checkValidate(documentsImages, validations[DOCUMENTS_IMAGES], setErrorFiles),
        ]

        const isError = errors.filter(er => er.isError).length
        if (!isError && robot && phoneIsVerify) {

            dispatch(setLoadSpinner(true))

            signup(data)
                .then(async (response) => {
                    const useruid = response.data.useruid;
                    const userDocs = {
                        user_id: useruid,
                        files: documentsImages
                    };
                    return createUserDocsKK(userDocs).then((response) => {
                        viewAlert(dispatch, response)
                        setTimeout(() => {
                            navigate(`/${routesNames.AUTH}/${routesNames.LOGIN}`)
                            viewAlert(dispatch, response, {show: false})
                        }, 2000)
                    }).catch(({response}) => {
                        console.log("🚀 createUserDocsKK err res:", response);
                        viewAlert(dispatch, response, {
                            title: t('success'),
                            text: 'Save, but without docs',
                            type: 'warning'
                        });
                    })
                })
                .catch(({response}) => {
                    console.log("🚀 signup err res:", response);
                    viewAlert(dispatch, response)
                })
                .finally(() => dispatch(setLoadSpinner(false)))
        }
    }

    async function handleValues(value, name, inputRef) {
        switch (name) {
            case COMPANY_NAME:
                checkValidate(value, validations[name], setErrorCompany)
                break;
            case NAME:
                checkValidate(value, validations[name], setErrorName)
                break;
            case PHONE: {
                const validError = checkValidate(value, validations[name], setErrorPhone)
                setPhone(value)
                setPhoneIsVerify(false)
                if(value && !validError.isError) {
                    setPhoneIsCheckNumExistKK(false)
                    setErrorPhone({
                        isError: true,
                        message: t('phone_checking')
                    })
                    checkPhoneExistKK({phone: value}).then((response) => {
                        if(response.exist) {
                            setErrorPhone({
                                isError: true,
                                message: t('phone_already_registered')
                            })
                        } else if(response.status) {
                            setErrorPhone({
                                isError: true,
                                message: 'Error, status: ' + response.status
                            })
                        } else if(!response.exist) {
                            setErrorPhone({
                                isError: false,
                                message: ''
                            })
                        }
                        setPhoneIsCheckNumExistKK(true);
                    }).catch((err) => {
                        console.log("🚀  checkPhoneExistKK  err:", err);
                        setErrorPhone({
                            isError: true,
                            message: err.status ? 'Error, status: ' + err.status : err.toString()
                        })
                    })
                }
                break;
            }
            case IC_CRD_NUM:
                checkValidate(value, validations[name], setErrorCardNumber)
                break;
            case WECHAT_ID:
                checkValidate(value, validations[name], setErrorWechatID)
                break;
            case EMAIL:
                checkValidate(value, validations[name], setErrorEmail)
                break;
            case PASSWORD:
                setPass({...pass, first: value})
                checkValidate(value, validations[name], setErrorPassword)
                break;
            case PASSWORD_CONFIRM:
                setPass({...pass, second: value})
                checkValidate(value, validations[name].concat([matchValidate(pass.first, t('passwordMatch'))]), setErrorPasswordAgain)
                break;
            case DOCUMENTS_IMAGES: {
                if (inputRef && inputRef.current && inputRef.current.files && inputRef.current.files[0] && documentsImages && documentsImages.length < 5) {
                    const files = inputRef.current.files;
                    const newFiles = [...documentsImages];
                    for (let i = 0; i < files.length; i++) {
                        if (documentsImages.length + i + 1 > 5) break;
                        const file = files[i];
                        if (file.size > 1048576*5) { // 1MB - 1048576 B
                            break;
                        }
                        const fileData = {
                            file_name: file.name,
                            type: file.type,
                            size: file.size,
                            lastModified: file.lastModified,
                            content: ""
                        };
                        await convertBase64(file).then(resBase64 => {
                            fileData.content = resBase64;
                            newFiles.push(fileData);
                        }).catch(err => {
                            console.log("🚀 load file error:", err);
                        });
                    }
                    setDocumentsImages(newFiles);
                    checkValidate(newFiles, validations[name], setErrorFiles);
                    for (let i = 0; i < files.length; i++) {
                        if (documentsImages.length + i + 1 > 5) break;
                        const file = files[i];
                        if (file.size > 1048576*5) { // 1MB - 1048576 B
                            setErrorFiles({
                                isError: false,
                                message: size_5_MB
                            });
                            setTimeout(()=> {
                                if(errorFilesRef.current.message === size_5_MB) {
                                    setErrorFiles({
                                        isError: false,
                                        message: ''
                                    });
                                }
                            }, 1000*3);
                            break;
                        }
                    }
                }
                break;
            }
            case INPUT_CAPTCHA:
                checkValidate(value, validations[name], setErrorCaptcha)
                break;
            case MANAGER:
                setManager(value);
                checkValidate(value, validations[name], setErrorManager)
                break;
            case CAPTCHA_SWITCH:
                setRobot(value);
                break;
            default:
                break;
        }
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                const base64result = fileReader.result.substr(fileReader.result.indexOf(',') + 1);
                resolve(base64result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    function removeImg(i) {
        const newFiles = [...documentsImages];
        newFiles.splice(i, 1);
        setDocumentsImages(newFiles);
    }

    function onCodeCheck(isVerify, connectionId, verifyCode) {
        setPhoneIsVerify(isVerify);
        setConnectionId(connectionId);
        setVerifyCode(verifyCode);
    }

    const inputList = [
        {
            el: InputText,
            id: COMPANY_NAME,
            classes: '',
            placeholder: t('your_company'),
            errorMessage: errorCompany.message,
            handleChange: handleValues,
            validations: validations[COMPANY_NAME],
            closeButton: true,
            required: true
        },
        {
            el: InputName,
            id: NAME,
            classes: 'mt-4',
            placeholder: t('your_name'),
            errorMessage: errorName.message,
            handleChange: handleValues,
            validations: validations[NAME],
            closeButton: true,
            required: true
        },
        {
            el: InputPhone,
            id: PHONE,
            classes: 'mt-4',
            placeholder: t('phone_number'),
            errorMessage: errorPhone.message,
            handleChange: handleValues,
            validations: validations[PHONE],
            closeButton: true,
            required: true
        },
        {
            el: PhoneVerify,
            id: PHONE_VERIFY,
            classes: 'mt-4',
            placeholder: t('verification_code'),
            errorMessage: errorPhone.isError,
            handleChange: onCodeCheck,
            validations: [],
            closeButton: true,
            required: true,
            value: phone,
        },
        {
            el: InputIdCardNumber,
            id: IC_CRD_NUM,
            classes: 'mt-4',
            placeholder: t(IC_CRD_NUM),
            errorMessage: errorCardNumber.message,
            handleChange: handleValues,
            validations: validations[IC_CRD_NUM],
            closeButton: true,
            required: true
        },
        {
            el: InputText,
            id: WECHAT_ID,
            classes: 'mt-4',
            placeholder: t('wechatID'),
            errorMessage: errorWechatID.message,
            handleChange: handleValues,
            validations: validations[WECHAT_ID],
            closeButton: true,
            required: true
        },
        {
            el: InputEmail,
            id: EMAIL,
            classes: 'mt-4',
            placeholder: t(EMAIL),
            errorMessage: errorEmail.message,
            handleChange: handleValues,
            validations: validations[EMAIL],
            closeButton: true,
            required: true
        },
        {
            el: InputPassword,
            id: PASSWORD,
            classes: 'mt-4',
            placeholder: t(PASSWORD),
            errorMessage: errorPassword.message,
            handleChange: handleValues,
            validations: validations[PASSWORD],
            closeButton: true,
            required: true,
            value: pass.first,
        },
        {
            el: InputPassword,
            id: PASSWORD_CONFIRM,
            classes: 'mt-4',
            placeholder: t(PASSWORD_AGAIN),
            errorMessage: errorPasswordAgain.message,
            handleChange: handleValues,
            validations: validations[PASSWORD_CONFIRM],
            closeButton: true,
            required: true,
            value: pass.second,
        },
    ]

    function selectLabelText() {
        return <div className="row">
            <div className="col">{t('Upload_documents')}</div>
            <i
                className="fa fa-question col-auto w-0 m-p-0 right-icon cursor-def"
                title={t('Upload_documents_title')}
                onClick={e => {
                    e.stopPropagation();
                }}>
            </i>
        </div>
    }

    return (
        <Form onSubmit={handleSubmit} id={'registration-form'}>
            {
                inputList.map((item, key) => {
                    return (
                        <item.el
                            key={key}
                            id={item.id}
                            classes={item.classes}
                            placeholder={item.placeholder}
                            errorMessage={item.errorMessage}
                            handleChange={item.handleChange}
                            handleBlur={item.handleChange}
                            validations={item.validations}
                            closeButton={item.closeButton}
                            value={item.value}
                            customMask={item.mask}
                            required={item.required}
                            isCheckNumExistKK={item.isCheckNumExistKK}
                        />
                    )
                })
            }
            <InputFile
                id={DOCUMENTS_IMAGES}
                groupClssses={'mt-4'}
                labelClssses={'mtb-0 h42'}
                buttonClssses={'h42'}
                selectLabelText={selectLabelText()}
                errorMessage={errorFiles.message}
                hideSpamWithoutError={true}
                handleChange={handleValues}
                validations={validations[DOCUMENTS_IMAGES]}
                closeButton={true}
                files={documentsImages}
                required={true}
                multiple={true}
            />
            {(documentsImages && documentsImages.length) ?
                <div className="mt-1">
                    {documentsImages.map((imgData, i) => {
                        return <div className="row align-items-center h28 mw-100 mx-0" key={i}>
                            <div
                                onClick={() => removeImg(i)}
                                className={`clear-btn-left-s cursor-pointer col-auto px-0`}
                            >
                                <img src="/assets/img/close-blue.svg" alt="clear-search"/>
                            </div>
                            <b className={`col`}>{imgData.file_name}</b>
                        </div>
                    })}
                </div>
                : null
            }
            <Select
                id={'manager'}
                className={'mt-4 text-center'}
                options={managerList}
                selectValue={manager}
                errorMessage={errorManager.message}
                loaded={loadManagerList}
                keyName={'GUID'}
                nameValue={'FullName'}
                handleChange={handleValues}
                required
            />
            <Form.Group controlId={'recaptcha'} className={'mb-3 mt-4'}>
                <div className={'d-flex justify-content-center align-items-center mb-4'}>
                    <Switch
                        id={CAPTCHA_SWITCH}
                        handle={handleValues}
                        isInvalid={trySubmit && !robot}
                        defaultValue={robot}
                        labelText={'I am not a robot'}
                    />
                </div>
            </Form.Group>

            <Button type="submit" className={'w-100'}>
                {t('register')}
            </Button>

            <Button onClick={() => navigate(`/${routesNames.AUTH}/${routesNames.LOGIN}`)}
                    variant="outline-primary" type="submit" className={'w-100 mt-3'}>
                {t('cancel')}
            </Button>

        </Form>
    );
};

export default RegisterForm;

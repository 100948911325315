import React        from 'react';
import { Button }   from "react-bootstrap";

const ModalButtons = ({className, buttons}) => {

    const buts = Object.entries(buttons ? buttons : {});
    const showButtons = !!buts.length;

    return (
        <div className={`modal-form-buttons-block ${className ? className : ''}`}>
            {showButtons ? <hr/> : null}
            {
                showButtons ?
                    <div className={'d-flex flex-column flex-lg-row justify-content-lg-end'}>
                        {
                            buts.map(
                                button => {
                                    let action = button[1]?.action ? button[1]?.action : () => {}

                                    return <Button
                                        style={{
                                            minWidth: '114px',
                                        }}
                                        className={'modal-form-button'}
                                        onClick={action}
                                        type={button[1].type}
                                        key={button[0]}
                                        variant={button[1].variant}
                                        disabled={button[1].disabled}
                                        hidden={button[1].hidden}
                                    >
                                        {button[1].text}
                                    </Button>
                                }
                            )
                        }
                    </div>
                    : null
            }
        </div>
    );
};

export default ModalButtons;

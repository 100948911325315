import React from 'react';

const Logo = ({type, className, styleLogo, id, goTo}) => {

    function goToMain() {
        if (goTo && typeof goTo === 'function')
            goTo();
        else
            window.location = '/';
    }

    return (
        <div onClick={goToMain} id={id ?? ''} className={`${className} cursor-pointer`}>
            <img style={{...styleLogo}} src={type === 'white' ? "/assets/img/logo-white.svg" : "/assets/img/logo.svg"} alt="logo"/>
        </div>
    );
};

export default Logo;

import React, { useState, useEffect } from 'react';
import {useDispatch} from "react-redux";
import {Form} from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import {useTranslation} from "react-i18next";

import ModalButtons from "../shared/modal/ModalButtons";
import InputText from "../input/InputText";
import {noEmptyValidate} from "../../utils/validate";
import {saveAnswer} from "../../utils/api";
import {hideModalForm} from "../../utils/misc";
import Select from "../input/Select";
import {setPoll} from "../../storage/global";

const PollForm = ({poll}) => {

    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();

    const formRef = React.useRef();
    const FORM_ID = 'FORM-QUESTION'

    const lang = i18n.language || 'en'

    function buildAnswerForm(poll) {
        return {
            id_question: poll.id,
            answers: poll.questions ? poll.questions.map((que) => ({
                en: que.en,
                cn: que.cn,
                type: que.type,
                require: que.require,
                value: que.type === 1 ? t('nothingSelected') : ''
            })) : []
        }
    }

    const [pollAnswers, setPollAnswers] = useState(buildAnswerForm(poll))

    useEffect(() => {
        setPollAnswers(buildAnswerForm(poll))
    }, [poll])

    function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();

        if (!notValid()) {

            const requestData = {
                data: {
                    id_question: pollAnswers.id_question,
                    answers: pollAnswers.answers.map((que) => ({
                        en: que.en,
                        cn: que.cn,
                        value: que.value
                    }))
                }
            }

            saveAnswer(requestData.data)
                .then(() => {
                    dispatch(setPoll({}));
                    hideModalForm(dispatch)();
                })
                .catch(({response}) => {
                    console.log('saveAnswer: ', response)
                })
        }

        console.log('YES')
    }

    function hideForm() {
        dispatch(setPoll(poll));
        hideModalForm(dispatch)();
    }

    function notValid(): boolean {
        const isErr = !!pollAnswers.answers.find((el) => {
            const errorValue = el.type === 0 ? (!el.value.length || el.value === t('validEmpty')) : el.value === t('nothingSelected')
            return el.require && errorValue
        })
        return isErr
    }

    function saveChange(i, val) {
        const newAnswers = JSON.parse(JSON.stringify(pollAnswers));
        newAnswers.answers[i].value = val;
        setPollAnswers(newAnswers)
    }

    return (
        <div>
            <div className={'position-relative'}>
                {poll.require ? null : <img
                    onClick={hideForm}
                    style={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        backgroundColor: 'white',
                        border: '1px solid #8492a8',
                        borderRadius: '100%',
                        zIndex: 10
                    }}
                    className={'my-modal-close cursor-pointer'} src="/assets/img/close.svg"
                    alt="close"/>
                }
            </div>
            <Form ref={formRef} noValidate onSubmit={handleSubmit} className={''}>
                <>
                    {poll.require ? <h4>{t('survey_required')}</h4> : null}
                    <table id={FORM_ID} width={'100%'}>
                        <tbody>
                        {poll.questions && poll.questions.length && poll.questions.map( (que, i) => {
                            const nothingSelected = t('nothingSelected');
                            const validEmpty = t('validEmpty');
                            const varList = que.variants.map( el => el[lang]);
                            const variants = [nothingSelected, ...varList];
                            const answer = pollAnswers.answers[i].value;
                            const errorValue = answer === nothingSelected || answer === validEmpty || !answer.trim().length;
                            const errorMessage = que.require && errorValue ? validEmpty : '';

                            return <tr key={i}>
                                <td>
                                    <div className={'p-2 pt-3'}>
                                        <p className={'m-0'}><small>{que[lang]}</small></p>
                                        {que.type === 0 ?
                                            <InputText
                                                id={'q-7'}
                                                handleChange={ val => saveChange(i, val)}
                                                value={pollAnswers.answers[i].value}
                                                closeButton
                                                errorMessage={errorMessage}
                                                validations={noEmptyValidate(validEmpty)}
                                                placeholder={validEmpty}
                                            />
                                            : <Select
                                                id={'q-1'}
                                                required={que.require}
                                                options={variants}
                                                selectValue={pollAnswers.answers[i].value}
                                                handleChange={val => saveChange(i, val)}
                                                errorMessage={errorMessage}
                                                validations={noEmptyValidate(validEmpty)}
                                                dataTip={pollAnswers.answers[i].value}
                                            />
                                        }

                                    </div>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                    <ReactTooltip effect={'solid'} className={'my-tooltip'} delayHide={100}/>
                    <ModalButtons buttons={{
                        save: {
                            text: t('save'),
                            variant: 'primary',
                            type: 'submit',
                            disabled: notValid()
                        }
                    }}/>
                </>
            </Form>
        </div>
    );
};

export default PollForm;



import React, { useMemo, useRef }       from 'react';
import { useDispatch, useSelector }     from "react-redux";
import ReactTooltip                     from "react-tooltip";
import { useTranslation }               from "react-i18next";

import BalanceMoney                     from "./BalanceMoney";
import ChargeForm                       from "../forms/ChargeForm";
import { setModalForm, setShowMenu }    from "../../storage/global";

const BalanceButton = () => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const refB1 = useRef();
    const refB2 = useRef();

    const text_B1 = 'DUE';
    const text_B2 = 'Balance';

    const profileInfo = useSelector(state => state.global.profile);
    const balanceNotPaid = useSelector(state => state.global.balanceNotPaid);
    const balanceFree = useSelector(state => state.global.balance_free);
    const balance = useSelector(state => state.global.balance)//delete

    const credit = useMemo(() => {
        return isNaN(Number(balanceFree)) ? 0 : Number(balanceFree)
    }, [balanceFree])

    // console.log(balance, balanceNotPaid)

    function openForm(isPay) {

        dispatch(setShowMenu(false))

        dispatch(setModalForm({
            title: '',
            form: <ChargeForm isPay={isPay}/>
        }))
    }

    function handleHover(event, ref, isHover = true) {
        if (isHover)
            ReactTooltip.show(ref)
        else
            ReactTooltip.hide(ref)
    }

    return (
        <div className={'d-flex'}>
            <div
                ref={refB1}
                onMouseOver={event => handleHover(event, refB1)}
                onMouseOut={event => (event, refB1, false)}
                data-tip={text_B1}
                onClick={() => openForm(false)}
                className={'cursor-pointer d-flex me-2'}
            >
                <img src="/assets/img/wallet-danger.svg" alt="wallet"/>
                <BalanceMoney
                    className={'ml-1 d-flex align-items-center'}
                    // balance={balanceNotPaid?.debt ? balanceNotPaid?.debt * -1 : 0}
                    balance={balance}//Delete
                    currency={profileInfo.sign}
                />
            </div>
            {/*<div*/}
            {/*    ref={refB2}*/}
            {/*    onMouseOver={event => handleHover(event, refB2)}*/}
            {/*    onMouseOut={event => (event, refB2, false)}*/}
            {/*    data-tip={text_B2}*/}
            {/*    onClick={() => openForm(true)}*/}
            {/*    className={'cursor-pointer d-flex'}*/}
            {/*>*/}
            {/*    <img width={26} src="/assets/img/money-green.svg" alt="wallet"/>*/}
            {/*    <BalanceMoney*/}
            {/*        className={'ml-1 d-flex align-items-center'}*/}
            {/*        balance={credit < 0 ? 0 : credit.toFixed(2)}*/}
            {/*        currency={profileInfo.sign}*/}
            {/*        successColor={'my-text-success'}*/}
            {/*    />*/}
            {/*</div>*/}
        </div>
    );
};

export default BalanceButton;

import React, {useEffect, useState} from 'react';
import PropTypes           from 'prop-types'
import { useTranslation }  from "react-i18next";

import InputText           from "./InputText";
import { EMAIL }           from "../../constants";


const InputEmail = ({
                        id,
                        type,
                        placeholder,
                        classes,
                        errorMessage,
                        required,
                        value,
                        closeButton,
                        handleChange,
                        handleBlur,
                        validations,
                    }) => {

    const {t} = useTranslation();

    const [email, setEmail] = useState(value);

    useEffect(() => {
        value !== email && setEmail(value)
    }, [value])

    function handleValue(value, name, resultValidation) {
        if (typeof handleChange === 'function')
            handleChange(value, name, resultValidation)
        setEmail(value)
    }

    return (
        <InputText
            id={id ?? EMAIL}
            type={type ?? 'email'}
            placeholder={placeholder ?? t(EMAIL)}
            classes={classes}
            errorMessage={errorMessage}
            required={required}
            value={email}
            handleChange={handleValue}
            closeButton={closeButton}
            validations={validations}
            handleBlur={handleBlur}
        />
    );
};

InputEmail.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    classes: PropTypes.string,
    errorMessage: PropTypes.string,
    required: PropTypes.bool
}

export default InputEmail;

import React, { useEffect, useState } from 'react';
import { Button, Form }               from "react-bootstrap";
import PropTypes                      from "prop-types";
import { useTranslation }             from "react-i18next";

import InputText                      from "./InputText";
import { validate }                   from "../../utils/misc";
import { DEFAULT_ERROR_OBJECT, DOCUMENTS_IMAGES }       from "../../constants";
import LoadInput                      from "../shared/LoadInput";
import CloseInputButton               from "../shared/CloseInputButton";
import InputSelectErrorBlock          from "../shared/InputSelectErrorBlock";

const accessImgType = [
    'image/png',
    'image/jpeg',
    'image/tiff',
    'image/raw',
    'image/dng',
    'image/gif',
    'application/pdf',
];

const InputFile = ({
        id,
        selectLabelText,
        groupClssses,
        required,
        files,
        closeButton,
        labelClssses,
        buttonClssses,
        handleInput,
        handleChange,
        dangerFileInput,
        errorMessage,
        hideSpamWithoutError,
        multiple
    }) => {

    const { t } = useTranslation();

    return (
        <InputText
            id={id}
            type={'file'}
            classes={''}
            errorMessage={errorMessage}
            errorStyles={{position: 'relative'}}
            hideSpamWithoutError={hideSpamWithoutError}
            groupClssses={groupClssses}
            labelClssses={labelClssses}
            buttonClssses={buttonClssses}
            handleChange={handleChange}
            acceptFiles={accessImgType.join(',')}
            selectLabelText={selectLabelText}
            label
            hidden
            closeButton={false}
            required
            dangerFileInput={!files.length}
            multiple
        />
    );
};

export default InputFile;

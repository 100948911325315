import React, { useEffect, useState }                                        from 'react';
import { useDispatch }                                                       from "react-redux";
import { Button }                                                            from "react-bootstrap";

import { setLoad, setLoadSpinner }                                           from "../../storage/global";
import { getDangerousGoods, saveDangerWorlds } from "../../utils/api";
import { viewAlert }                                                         from "../../utils/misc";
import InputText                                                             from "../../components/input/InputText";
import uniqueArray                                                           from "../../utils/uniqueArray";

const DANGER_GOODS = 'danger-goods'

const TabSystemSettings = () => {

    const dispatch = useDispatch();

    const [dangerWorlds, setDangerWorlds] = useState({
        dangerWorlds: [],
        rootDangerWorlds: []
    })
    const [world, setWorld] = useState('');
    
    useEffect(() => {
        dispatch(setLoad(false))

        getDangerWorlds()
    }, [])

    function getDangerWorlds() {
        dispatch(setLoadSpinner(true))
        return getDangerousGoods()
            .then(response => setDangerWorlds({dangerWorlds: [...response.data], rootDangerWorlds: [...response.data]}))
            .catch(({response}) => {viewAlert(dispatch, response)})
            .finally(() => dispatch(setLoadSpinner(false)))
    }

    function addDangerGoods(event) {
        const input = document.getElementById(DANGER_GOODS)
        setDangerWorlds({
            ...dangerWorlds,
            dangerWorlds: uniqueArray([...dangerWorlds.dangerWorlds, input.value.toString().toUpperCase()])
        })
        setWorld('')
    }

    function removeDangerGoods(value) {
        setDangerWorlds({...dangerWorlds, dangerWorlds: dangerWorlds.dangerWorlds.filter(v => v !== value)})
    }

    function reset() {
        setDangerWorlds({...dangerWorlds, dangerWorlds: [...dangerWorlds.rootDangerWorlds]})
    }

    async function saveData() {
        dispatch(setLoadSpinner(true))
        try {
            const responseDangerWorlds = await saveDangerWorlds(dangerWorlds);
            viewAlert(dispatch, {responseDangerWorlds})
        } catch(e) {
            viewAlert(dispatch, {"err": e})
        }
        dispatch(setLoadSpinner(false))
    }

    function handleValues(value, name, inputRef) {
        switch (name) {
            case DANGER_GOODS:
                setWorld(value)
                break;
            default:
                break;
        }
    }

    return (
        <div className={''}>
            <div className={'row mt-3'}>
                <div className={'col-12 col-lg-6'}>
                    <div className={'row'}>
                        <div className={'col'}>
                            <InputText handleChange={handleValues} value={world} id={DANGER_GOODS}
                                       placeholder={'Небезпечні товари'} closeButton/>
                        </div>
                        <div className={'col-auto'}>
                            <Button onClick={addDangerGoods}>Додати</Button>
                        </div>
                    </div>
                </div>
                <div className={'col-12 col-lg-6 mt-3 mt-lg-0'}>
                    <div className={'p-3'} style={{
                        border: '1px solid red',
                        borderRadius: '15px',
                        height: 'auto',
                        maxHeight: '20rem',
                        overflow: "auto",
                        backgroundColor: '#FFF'
                    }}>
                        {
                            dangerWorlds.dangerWorlds.map((v, k) => {
                                return <div key={k} className={'position-relative'} style={{
                                    border: '1px solid blue',
                                    borderRadius: '10px',
                                    padding: '5px',
                                    margin: '5px',
                                    display: 'inline-block'
                                }}>
                                    <strong>{v}</strong>
                                    <Close onClick={event => removeDangerGoods(v)}/>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
            <div className={'row mt-3'}>
                <div className={'col-6'}>
                    <Button onClick={reset} className={'w-100'} variant={'outline-primary'}>Скинути</Button>
                </div>
                <div className={'col-6'}>
                    <Button onClick={saveData} className={'w-100'} variant={'primary'}>Зберегти</Button>
                </div>
            </div>
        </div>
    );
};

export default TabSystemSettings;

function Close(props) {
    return (
        <div {...props} className={'cursor-pointer position-absolute'} style={{
            top: 0,
            right: '10px',
        }}>
            <div style={{
                width: '15px',
                height: '2px',
                backgroundColor: 'red',
                transform: 'rotate(45deg)',
                position: 'absolute',
            }}/>
            <div style={{
                width: '15px',
                height: '2px',
                backgroundColor: 'red',
                transform: 'rotate(135deg)',
                position: 'absolute',
            }}/>
        </div>
    )
}

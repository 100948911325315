
import React from "react";

import { routesNames } from "../constants";
import AdminRequestPanel            from "../pages/admin/AdminRequestPanel";
import TabRequestSettingsList       from "../pages/admin/TabRequestSettings/TabRequestSettingsList";
import TabRequestSettingsStatistic  from "../pages/admin/TabRequestSettings/TabRequestSettingsStatistic";
import TabRequestStatisticNotif        from "../pages/admin/TabRequestSettings/TabRequestStatisticNotif";

export default [
    {
        path: '/' + routesNames.REQUEST_SETTINGS,
        element: <AdminRequestPanel/>,
        children: [
            {
                index: true,
                path: '/' + routesNames.REQUEST_SETTINGS_STATISTIC,
                element: <TabRequestSettingsStatistic/>
            },
            {
                path: '/' + routesNames.REQUEST_SETTINGS_LIST,
                element: <TabRequestSettingsList/>
            },
            {
                path: '/' + routesNames.EMAILS_FOR_WARNINGS,
                element: <TabRequestStatisticNotif/>
            },
        ],
    }
]

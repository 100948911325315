import React, { memo, useEffect, useMemo, useState }         from 'react';
import { useSelector, useDispatch }                          from "react-redux";
import ReactList                                             from 'react-list';
import ReactTooltip                                          from "react-tooltip";
import { Button }                                            from "react-bootstrap";

import {
    EMAIL,
    EMAILS,
    EDIT,
    DELETE
}               from "../../constants";
import {
    setResponsibleEmails,
    setNewResponsibleEmail,
    updateResponsibleEmail,

}                              from "../../storage/global";
import {
    createResponsibleEmail,
    editResponsibleEmail,
    deleteResponsibleEmail
}                                                            from "../../utils/api";
import { viewAlert }                                         from "../../utils/misc";
import HeadList                                              from "./HeadList";
import ItemHeadList                                          from "./ItemHeadList";
import RowList                                               from "./RowList";
import ItemList                                              from "./ItemList";
import NothingPage                                           from "./NothingPage";
import CheckBox                                              from "../input/CheckBox";
import InputText                                             from "../input/InputText";
import CopyIcon                                              from "./CopyIcon";
import ButtonAdmin                                           from "./pages_buttons/ButtonAdmin";


const ListResposibleEmails = ({
        classesRow,
        emptyListText,
    }) => {

    const dispatch = useDispatch();

    const responsibleEmails = useSelector(state => state.global.responsibleEmails);

    function handleHover(event, ref, isHover = true) {
        if (isHover)
            ReactTooltip.show(ref)
        else
            ReactTooltip.hide(ref)
    }

    function editEmail( index, value ) {
        const newStateEmails = [...responsibleEmails];
        newStateEmails[index].newValue = value
        dispatch(setResponsibleEmails(newStateEmails));
    }

    function saveEmail( emailItem ) {
        try {
            if (emailItem.email) {
                const reqVal = {oldResEmail: emailItem.email, newResEmail: emailItem.newValue};
                editResponsibleEmail(reqVal)
                dispatch(updateResponsibleEmail(reqVal));
            } else {
                createResponsibleEmail({resEmail: emailItem.newValue})
                dispatch(setNewResponsibleEmail(emailItem.newValue));
            }
        } catch(e) {
            viewAlert(dispatch, {status: 500}, {text: '', type: 'warning'})
        }
    }

    function undoEdit( emailItem, index ) {
        try {
            if (emailItem.email) {
                const newStateEmails = [...responsibleEmails];
                newStateEmails[index].newValue = emailItem.email
                newStateEmails[index].isEdit = false
                dispatch(setResponsibleEmails(newStateEmails));
            } else {
                const newStateEmails = [...responsibleEmails];
                delete newStateEmails[index];
                dispatch(setResponsibleEmails(newStateEmails));
            }
        } catch(e) {
            viewAlert(dispatch, {status: 500}, {text: '', type: 'warning'})
        }
    }

    function editResEmail(email, index) {
        try {
            if (email) {
                const newStateEmails = [...responsibleEmails];
                newStateEmails[index].isEdit = true;
                dispatch(setResponsibleEmails(newStateEmails));
            }
        } catch(e) {
            viewAlert(dispatch, {status: 500}, {text: '', type: 'warning'})
        }
    }

    function delEmail(email, index) {
        try {
            if (email) {
                deleteResponsibleEmail({ delEmail: email})
                const newStateEmails = [...responsibleEmails];
                delete newStateEmails[index];
                dispatch(setResponsibleEmails(newStateEmails));
            }
        } catch(e) {
            viewAlert(dispatch, {status: 500}, {text: '', type: 'warning'})
        }
    }

    function isUniq(email) {
        let isDuplicat = false;
        responsibleEmails.forEach( (emailData) => {
            if(emailData && email.newValue === emailData.email && email.email !== emailData.email) isDuplicat = true;
        });
        return !isDuplicat
    }

    const listHead = [
        {name: EMAILS, col: 18, text: 'Email', sortArrow: false, classNames: 'wfc minw-100'},
        {name: EDIT, col: '', text: 'Edit', sortArrow: false, classNames: 'wfc minw-100'},
        {name: EMAILS, col: '', text: 'Delete', sortArrow: false, classNames: 'wfc minw-100'}
    ]
    const listItem = [
        {name: EMAIL, col: 18, isTrackNumber: false, classNames: 'px-3 wfc', classTableItem: "w-100",
            getChildren: (emailItem, index) => {
                const isOk = emailItem.newValue && emailItem.isEdit && isUniq(emailItem);
                return emailItem.isEdit ? 
                <div className={'row justify-content-between w-100 pt-2'}>
                    <div className={'col-12 col-md-6 row pb-2'}>
                        <InputText
                            handleChange={ (value, id) => editEmail(index, value)}
                            id={'new-resposible-email'}
                            placeholder={'new resposible email'}
                            closeButton={true}
                            value={emailItem.newValue}
                            groupClssses={'pr-0 '}
                            errorMessage={!emailItem.newValue ? 'Вкажіть пошту' : isOk ? '' : "Така пошта вже є."}
                            errorStyles={{
                                display: 'contents'
                            }}
                        />
                    </div>
                    <div className={'col row justify-content-end'}>
                        <div onClick={() => isOk && saveEmail(emailItem)} className={`col-auto`}>
                            <Button variant={'outline-primary'} disabled={!isOk}>
                                <span>Зберегти</span>
                            </Button>
                        </div>
                        <div onClick={() => undoEdit(emailItem, index)} className={`col-auto`}>
                            <Button variant={'outline-primary'}>
                                <span>Відмінити</span>
                            </Button>
                        </div>
                    </div>
                </div>
                : <div className={'d-flex'}>
                    <div className={`text-minimise mr-1`}>
                        {emailItem.email}
                    </div>
                    <CopyIcon value={emailItem.email} styles={{height: '20px'}}/>
                </div>
            }
        },
        {name: EDIT, col: '', classNames: 'wfc minw-100', hidenInEdit: true, getChildren: (emailItem, index) => {
            return emailItem.isEdit ? null : <ButtonAdmin
                onClick={_ => editResEmail(emailItem, index)}
                text={'Edit'}
                buttonClasses={`wfc col-bg-primary`}
                iconClasses={""}
                key={index}/>
        }},
        {name: DELETE, col: '', classNames: 'wfc minw-100', hidenInEdit: true, getChildren: (emailItem, index) => {
            return emailItem.isEdit ? null : <ButtonAdmin
                onClick={_ => delEmail(emailItem.email, index)}
                text={''}
                buttonClasses={`wfc col-bg-primary`}
                iconClasses={"fa-trash"}
                key={index}/>
        }},
    ];

    return (
        <>
            <HeadList className={''}>
                {
                    listHead.map((v, k) =>
                        <ItemHeadList
                            key={k}
                            text={v.text}
                            col={v.col}
                            classNames={v.classNames}
                            sortArrow={v.sortArrow}
                            name={v.name}
                            onClick={ _ => {}}
                            order={v}
                        >{v.getChildren ? v.getChildren(v, k) : null}</ItemHeadList>)
                }
            </HeadList>
            <div className={'page-body minh-300 w-100'} style={{}}>
                <ReactList
                    pageSize={50}
                    length={responsibleEmails.length}
                    type='uniform'
                    itemRenderer={(index, key) => {
                        const item = responsibleEmails[index];

                        return item && <RowList item={item}
                                className={classesRow || ' w-100'}
                                key={key}
                            >
                            {
                                listItem.map((value, k) => value.hidenInEdit && item.isEdit ? null : <ItemList
                                    key={key + k}
                                    item={item}
                                    name={value.name}
                                    col={item.isEdit ? '' : value.col}
                                    classNames={item.isEdit ? 'px-3 w-100' : value.classNames}
                                    isTrackNumber={value.isTrackNumber}
                                    handleHover={handleHover}
                                    click={value.click}
                                    attr={''}
                                    copy={value.copy}
                                    afterLoadData={value.afterLoadData}
                                    classTableItem={value.classTableItem}
                                >{value.getChildren ? value.getChildren(item, index) : null}</ItemList>)
                            }
                        </RowList>
                    }}
                />
                {responsibleEmails.length ? null : <NothingPage searchLength={null} emptyListText={emptyListText}/>}
            </div>

            <ReactTooltip className={'my-tooltip'} delayHide={100} effect={'solid'}/>

        </>
    );
};

export default memo(ListResposibleEmails);
